import SharedButtons from "@/apps/Vank/Page/shared/SharedButtons/SharedButtons";
import {
  decryptInfo,
  formtDateModal,
  TranslatedDate,
  translateAcountType,
  capitalizeName,
} from "@/apps/Vank/Page/utils/Utilfunctions";
import { IconClose } from "@/assets/IconV2/IconsSvg";
import COP from "@assets/img/COP.png";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import succes from "@assets/img/Success.png";
import error from "@assets/img/error.png";
import pending from "@assets/img/pending.png";
import { formatBalance } from "@/apps/Vank/Page/Home/Home";
import { motion } from "framer-motion";

function ModalApprovedShipping({
  isMoneyModalOpen,
  data,
  onClose,
}: {
  isMoneyModalOpen: any;
  data: any;
  onClose: () => void;
}) {
  const [t, i18n] = useTranslation("global");
  const [fill, setFill] = useState<string>("var(--content-main-gray-light)");

  // Función para capitalizar nombres
  const capitalizeName = (name) => {
    if (!name) return name; // Si es un correo, lo devolvemos tal cual
    return name
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  let accountType =
    data.BANKACCOUNTTYPENAME[0].toUpperCase() +
    data.BANKACCOUNTTYPENAME.slice(1).toLowerCase();
  let account = t(`accountTypes.${accountType}`);

  return (
    <motion.div
      className={`flex ${
        data?.STATUSORDER == 3 || data?.STATUSORDER == 4
          ? "2xl:border-2 xl:border-2 lg:border-2 tablet:border-2 mobile:border-0 border-[#FAA6A6]"
          : "border-none"
      } 2xl:w-[540px] xl:w-[540px] lg:w-[540px] tablet:w-[540px] mobile:w-full bottom-0 
                            2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute  flex-col justify-center 2xl:rounded-[16px] xl:rounded-[16px] lg:rounded-[16px] tablet:rounded-[16px] mobile:rounded-t-[1rem]
                            items-center  2xl:2xl:gap-[24px] xl:gap-[5px] md:gap-[0px] bg-[var(--bacground-component-module-light)] dark:bg-[#1A1A1B] `}
      initial={isMoneyModalOpen ? { y: "100%" } : { y: 0 }} // Solo en móvil comienza fuera de la pantalla
      animate={{ y: 0 }} // Siempre termina en su posición original
      exit={isMoneyModalOpen ? { y: "100%" } : { y: 0 }} // Sale hacia abajo solo en móvil
      transition={{ duration: 0.5 }} // Duración y suavizado de la transición
    >
      <div className="w-full  justify-between items-center 2xl:pb-[24px] xl:pb-[24px]  lg:pb-[24px] tablet:pb-[24px] mobile:pb-[10px] 2xl:h-auto  xl:h-auto  lg:h-auto  tablet:h-auto mobile:h-auto max-h-[85vh] 2xl:rounded-[16px] xl:rounded-[16px] lg:rounded-[16px] tablet:rounded-[16px] mobile:rounded-t-[8px] gap-3 flex flex-col">
        <div className="flex  flex-col min-h-12 items-start  gap-[16px] w-full">
          <div className="flex flex-col items-start justify-center   w-full">
            <div className="flex h-full justify-between 2xl:py-4 xl:py-4 lg:py-4 tablet:py-4 mobile:py-2 2xl:px-[24px] xl:px-[24px] lg:px-[24px] tablet:px-[24px] mobile:px-[16px] w-full items-center">
              <p className="text-[var(--content-main-black-light)] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px] font-semibold dark:text-[#FFFFFF]">
                {t(
                  "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Title"
                )}
              </p>
              <div
                onMouseEnter={() =>
                  setFill("var(--content-inner-inner-white-light)")
                }
                onMouseLeave={() => setFill("var(--content-main-gray-light)")}
                onClick={onClose}
                className={`h-8 w-8 cursor-pointer rounded-full p-0 flex justify-center items-center border border-[#F1F1F1] hover:bg-[var(--background-tab-selected)]`}
              >
                <IconClose fill={fill} />
              </div>
            </div>
            <div className="h-[1px] w-full bg-[var(--strokes-separator-separator-light)] dark:bg-[#2D2E2F]"></div>
          </div>
        </div>
        <div className="w-full h-full overflow-auto">
          <div className="w-full flex justify-center">
            <div
              className="flex justify-center items-center 2xl:gap-[10px] xl:gap-[10px] lg:gap-[10px] tablet:gap-[10px] mobile:gap-[8px] rounded-[44px] 
                                    2xl:w-[50px] xl:w-[50px] lg:w-[50px] tablet:w-[50px] mobile:w-[42px]
                                    2xl:h-[50px] xl:h-[50px] lg:h-[50px] tablet:h-[50px] mobile:h-[42px]
                    "
            >
              {data?.STATUSORDER == 1 ? (
                <img src={succes} alt="" />
              ) : data?.STATUSORDER == 2 ? (
                <img src={pending} alt="" />
              ) : (
                <img src={error} alt="" />
              )}
            </div>
          </div>

          <div className=" flex 2xl:px-[24px] xl:px-[24px] lg:px-[24px] tablet:px-[24px] mobile:px-[16px] flex-col tablet:h-auto mobile:h-auto items-center 2xl:gap-[24px] xl:gap-[24px] lg:gap-[24px] tablet:gap-[24px] mobile:gap-[8px] w-full">
            {data?.STATUSORDER == 1 ? (
              <div className="flex flex-col items-center 2xl:gap-[8px] xl:gap-[8px] lg:gap-[8px] tablet:gap-[6px] mobile:gap-[2px] w-full">
                <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px] text-[var(--content-main-black-light)] text-center font-semibold dark:text-[#FFFFFF] 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Success.Title"
                  )}
                </p>{" "}
                {/**mobile */}
                <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px] text-[var(--content-main-black-light)] text-center font-semibold dark:text-[#FFFFFF] 2xl:block xl:block lg:block tablet:block mobile:hidden">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Success.Title2"
                  )}
                </p>
                <p className="text-[var(--content-main-gray-light)] text-center 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                  {TranslatedDate(data.GENERATEDDATE, i18n.language)}
                </p>
                <p className="text-[var(--content-main-gray-light)] text-center 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Success.TransactionId"
                  )}{" "}
                  <span className="font-medium">{data?.CODERECEIPT}</span>
                </p>
              </div>
            ) : data?.STATUSORDER == 2 ? (
              <div className="flex flex-col items-center 2xl:gap-[8px] xl:gap-[8px] lg:gap-[8px] tablet:gap-[8px] mobile:gap-[2px] w-full">
                <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px] text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-center font-semibold 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Process.Title"
                  )}
                </p>{" "}
                {/**mobile */}
                <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px] text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-center font-semibold 2xl:block xl:block lg:block tablet:block mobile:hidden">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Process.Title2"
                  )}
                </p>
                <p className="text-[var(--content-main-black-light)] dark:text-[#FFFFFF] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-medium text-center 2xl:leading-normal xl:leading-normal lg:leading-normal tablet:leading-normal mobile:leading-[0.9rem]">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Process.SubText"
                  )}
                </p>
                <p className="text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-center 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal">
                  {TranslatedDate(data.GENERATEDDATE, i18n.language)}
                </p>
                <p className="text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-center 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Process.TransactionId"
                  )}{" "}
                  <span className="font-medium">{data?.CODERECEIPT}</span>
                </p>
              </div>
            ) : (
              <div className="flex flex-col items-center 2xl:gap-[8px] xl:gap-[8px] lg:gap-[8px] tablet:gap-[8px] mobile:gap-[2px] w-full">
                <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px] text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-center font-semibold 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Error.Title"
                  )}
                </p>{" "}
                {/**mobile */}
                <p className="2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px] text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-center font-semibold 2xl:block xl:block lg:block tablet:block mobile:hidden">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Error.Title2"
                  )}
                </p>
                <p className="w-full px-4 text-[var(--content-main-black-light)] dark:text-[#C0C0C1] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] text-center 2xl:leading-4 xl:leading-4 lg:leading-4 tablet:leading-4 mobile:leading-[0.9rem] font-[500]">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Error.SubText"
                  )}{" "}
                </p>
                <p className="text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)] text-center 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal">
                  {TranslatedDate(data.GENERATEDDATE, i18n.language)}
                </p>
                <p className="text-[var(--content-main-gray-light)] dark:text-[var(--content-main-gray-dark)] font-medium text-center 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px]">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Success.TransactionId"
                  )}
                  <span className="font-medium">{data?.CODERECEIPT}</span>
                </p>
              </div>
            )}

            <div className=" flex flex-col items-start 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] tablet:gap-[16px] mobile:gap-[8px] w-full">
              <div className=" flex justify-center items-center gap-1 w-full">
                <div className="w-[28px] h-[28px] me-2">
                  <img src={COP} alt="" />
                </div>
                <div className=" flex items-center justify-center">
                  <p className="flex justify-center items-center font-semibold text-[18px] text-[var(--content-main-black-light)] dark:text-[#FFFFFF]">
                    {formatBalance(data?.AMOUNT)}{" "}
                    <span className="font-[600] mx-2"> {data?.ASSETNAME}</span>
                  </p>
                </div>
              </div>
              <div className=" flex 2xl:p-[16px] xl:p-[16px] lg:p-[16px] tablet:p-[16px] mobile:p-[8px] flex-col items-start 2xl:gap-[8px] xl:gap-[8px] lg:gap-[8px] tablet:gap-[8px] mobile:gap-[2px] rounded-[12px] bg-[var(--background-layaout-light)] dark:bg-[#2D2E2F] w-full">
                <div className="w-full flex justify-between items-center">
                  <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1] 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex">
                    {t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text1"
                    )}
                  </p>
                  <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1] 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden">
                    {t(
                      "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sendto2"
                    )}
                  </p>
                  <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF]">
                    {capitalizeName(data?.NAME?.split(" ")[0].toLowerCase())}{" "}
                    {capitalizeName(data?.SURNAME?.split(" ")[0].toLowerCase())}
                  </p>
                </div>
                <div className="w-full flex justify-between items-center">
                  <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                    {t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text2"
                    )}
                  </p>
                  <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF]">
                    {decryptInfo(data?.IDENTITYNUMBER ?? "")}
                  </p>
                </div>
                <div className="h-[1px] w-full bg-[#F1F1F1] dark:bg-[#2D2E2F]"></div>
                <div className="w-full flex justify-between items-center">
                  <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                    {t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text3"
                    )}
                  </p>
                  <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF]">
                    {capitalizeName(data?.BANKNAME)}
                  </p>
                </div>
                {data.BANKACCOUNTTYPENAME && (
                  <div
                    className={`${
                      data?.STATUSORDER == 1 || data?.STATUSORDER == 2
                        ? "flex"
                        : "flex"
                    } w-full  justify-between items-center`}
                  >
                    <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                      {t(
                        "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text4"
                      )}
                    </p>
                    <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF] flex">
                      {account}
                    </p>
                  </div>
                )}
                {/* <div
                  className={`${
                    data?.STATUSORDER == 4 ? "flex" : "hidden"
                  } w-full  justify-between items-center`}
                >
                  <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1] 2xl:flex xl:flex lg:flex tablet:flex mobile:flex">
                    {t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text4"
                    )}
                  </p>
                  <p className="bg-red-500 text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF] 2xl:flex xl:flex lg:flex tablet:flex mobile:flex">
                    {account}
                  </p>
                </div> */}
                {/* <div className="h-[1px] w-full bg-[#F1F1F1] dark:bg-[#2D2E2F]"></div> */}
                <div className="w-full flex justify-between items-center">
                  <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                    {t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text5"
                    )}
                  </p>
                  <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-semibold dark:text-[#FFFFFF]">
                    {data?.ACCOUNT_NUMBER}
                  </p>
                </div>
                <div className={`${data?.STATUSORDER == 3 || data?.STATUSORDER == 4  ? 'block' : 'block'} w-full flex justify-between items-center 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden`}>
                                <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">{t('Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text7')}</p>
                                {/* <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-semibold dark:text-[#FFFFFF]"><span className="font-semibold">{data.FEE * 100}% </span>(${data.DISACOUNT} {data.ASSETNAME})</p> */}
                                <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-semibold dark:text-[#FFFFFF]">0</p>
                            </div>
                <div className="h-[1px] w-full bg-[#F1F1F1] dark:bg-[#2D2E2F]"></div>
                <div className="w-full flex justify-between">
                  <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                    {t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.text6"
                    )}
                  </p>
                  <p className="max-h-[40px] overflow-y-auto overflow-x-hidden  text-end text-[var(--content-main-black-light)] w-[14rem] break-words 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF]">
                    {data?.DESCRIPTION}
                  </p>
                </div>
              </div>
            </div>

            <div className="flex flex-col items-center 2xl:gap-[24px] xl:gap-[24px] lg:gap-[24px] tablet:gap-[24px] mobile:gap-[8px] w-full">
              <SharedButtons
                typeModule={3}
                URL={data?.IMGURL}
                TypeAction={data?.ACTION}
                CODERECEIPT={data?.CODERECEIPT}
                email={data?.EMAIL}
                
              />
              <div className="flex flex-col gap-[10px] w-full">
                {data?.STATUSORDER == 1 || data?.STATUSORDER == 4 ? (
                  <button
                    className="flex 2xl:py-[10px] xl:py-[10px] lg:py-[10px] tablet:py-[10px] mobile:py-[5px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] text-[var(--content-inner-inner-black-light)]  bg-[var(--branding-brand-yellow-light)] transition duration-300 hover:text-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] dark:hover:text-[#2D2E2F] dark:hover:bg-[#FFFFFF] group"
                    onClick={onClose}
                  >
                    <p className="flex justify-center items-center gap-[8px]  text-[16px] font-[600] transition duration-300 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                      {t(
                        "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn"
                      )}{" "}
                      {/**mobile */}
                    </p>
                    <p className="flex justify-center items-center gap-[8px]  text-[16px] font-[600] transition duration-300 2xl:block xl:block lg:block tablet:block mobile:hidden">
                      {t(
                        "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn"
                      )}
                    </p>
                  </button>
                ) : data?.STATUSORDER == 3 ? (
                  <button
                    className="flex 2xl:py-[10px] xl:py-[10px] lg:py-[10px] tablet:py-[10px] mobile:py-[5px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] text-[var(--content-inner-inner-black-light)]  bg-[var(--branding-brand-yellow-light)] transition duration-300 hover:text-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] dark:hover:text-[#2D2E2F] dark:hover:bg-[#FFFFFF] group"
                    onClick={onClose}
                  >
                    <p className="flex justify-center items-center gap-[8px]  text-[16px] font-[600] transition duration-300 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                      {t(
                        "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn"
                      )}{" "}
                      {/**mobile */}
                    </p>
                    <p className="flex justify-center items-center gap-[8px]  text-[16px] font-[600] transition duration-300 2xl:block xl:block lg:block tablet:block mobile:hidden">
                      {t(
                        "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn"
                      )}
                    </p>
                  </button>
                ) : (
                  <button
                    className="flex 2xl:py-[10px] xl:py-[10px] lg:py-[10px] tablet:py-[10px] mobile:py-[5px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] text-[var(--content-inner-inner-black-light)]  bg-[var(--branding-brand-yellow-light)] transition duration-300 hover:text-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] dark:hover:text-[#2D2E2F] dark:hover:bg-[#FFFFFF] group"
                    onClick={onClose}
                  >
                    <p className="flex justify-center items-center gap-[8px]  text-[16px] font-[600] transition duration-300 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                      {t(
                        "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn2"
                      )}{" "}
                      {/**mobile */}
                    </p>
                    <p className="flex justify-center items-center gap-[8px]  text-[16px] font-[600] transition duration-300 2xl:block xl:block lg:block tablet:block mobile:hidden">
                      {t(
                        "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn"
                      )}
                    </p>
                  </button>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </motion.div>
  );
}

export default ModalApprovedShipping;
