import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import { useNavigate } from "react-router-dom";
import "../CustomModal/CustomModal.css";

const CustomModalErrorHttp = ({ isOpen, setIsOpen, message }) => {
  return (
    <AnimatePresence>
      {isOpen && (
        <motion.div
          //   onClick={closeModalForm}
          className="Backdrop fixed inset-0 z-50 cursor-pointer flex justify-center items-center overflow-hidden"
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
            transition: {
              duration: 0.3,
            },
          }}
          exit={{
            opacity: 0,
          }}
        >
          <motion.div
            onClick={(e) => e.stopPropagation()}
            className="w-full max-w-[450px] max-h-min bg-[--color-lightest-white]  dark:bg-[#1A1A1B] cursor-default flex flex-col rounded-[24px] overflow-hidden"
            initial={{
              scale: 0,
            }}
            animate={{
              scale: 1,
              transition: {
                duration: 0.3,
              },
            }}
            exit={{
              scale: 0,
            }}
          >
            <div className="w-full h-[35%] pt-4 flex justify-center items-center ">
              <div className="p-5 w-28 h-28 bg-red-200 rounded-full">
                <svg
                  viewBox="0 0 48 48"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="#ff3838"
                  stroke="#ff3838"
                  stroke-width="0.00048000000000000007"
                  className="h-full w-full"
                >
                  <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                  <g
                    id="SVGRepo_tracerCarrier"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></g>
                  <g id="SVGRepo_iconCarrier">
                    {" "}
                    <title>exclamation-point</title>{" "}
                    <g id="Layer_2" data-name="Layer 2">
                      {" "}
                      <g id="invisible_box" data-name="invisible box">
                        {" "}
                        <rect width="48" height="48" fill="none"></rect>{" "}
                      </g>{" "}
                      <g id="icons_Q2" data-name="icons Q2">
                        {" "}
                        <g>
                          {" "}
                          <circle cx="24" cy="40" r="3"></circle>{" "}
                          <path d="M23.8,33h.4a2.2,2.2,0,0,0,2.1-2L28,7.3a4,4,0,1,0-8,0L21.7,31A2.2,2.2,0,0,0,23.8,33Z"></path>{" "}
                        </g>{" "}
                      </g>{" "}
                    </g>{" "}
                  </g>
                </svg>
              </div>
            </div>
            <div className="w-full h-[65%] px-5 py-4 flex flex-col items-center gap-6">
              <div className="flex flex-col justify-center items-center gap-3">
                <h2 className="text-center text-3xl font-bold mb-3 dark:text-[#FFFFFF]">
                  Error
                </h2>
                <p className="text-center text-xl leading-7 font-medium dark:text-[#FFFFFF]">
                  {message
                    ? message.message
                    : "¡Ups! Parece que tu sesión ha caducado. Por favor, vuelve a iniciar sesión para continuar explorando."}
                </p>
              </div>
              {message?.status !== "500" && (
                <button
                  className="w-full min-[1440px]:min-w-[353px] h-[50px] px-12 py-[5px] bg-[--color-dark-yellow] hover:bg-[--color-darkest-grey] dark:hover:bg-[#FFFFFF] dark:hover:text-[#2D2E2F] text-[#303030] hover:text-[--color-dark-yellow] disabled:opacity-75 disabled:cursor-default rounded-[500px] justify-center items-center gap-2.5 inline-flex cursor-pointer transition-all duration-300"
                  onClick={() => {
                    if (message?.status === "401")
                      return (window.location.href = "/Auth/SingIn");
                    setIsOpen(false);
                  }}
                >
                  {message?.status === "401" ? "Log In" : "Accept"}
                </button>
              )}
            </div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};

export default CustomModalErrorHttp;
