import React from "react";

const CustomDataLoading = () => {
  return (
    <div className="flex-col gap-4 w-full flex items-center justify-center">
      <div className="w-[40px] h-[40px] border-4 text-4xl animate-spin border-[#EAEAEA] flex items-center justify-center border-t-[#FFF133] rounded-full"></div>
    </div>
  );
};

export default CustomDataLoading;
