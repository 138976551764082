import "reflect-metadata"; // this should be the first import
import { StrictMode, Suspense } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";
import i18next from "i18next";

import es from "@assets/i18n/es.json";
import en from "@assets/i18n/en.json";
import pt from "@assets/i18n/pt.json";
import { I18nextProvider } from "react-i18next";
import { ThemeProvider } from "./Context/UseContext/ThemeContext.tsx";
import { RecoilRoot } from "recoil";
// import FullScreenLoader from "./apps/Shared/FullScreenLoader/FullScreenLoader.tsx";
import { bootstrap } from "./bootstrap.ts";
import ToastProvider from "./Context/ToastContext/ToastContext.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import './i18next.ts'

const queryClient = new QueryClient();

bootstrap()
  .then(({ container }) => {
    createRoot(document.getElementById("root")!).render(
      // <StrictMode>
      <QueryClientProvider client={queryClient}>
        <Suspense>
          <RecoilRoot>
            <ThemeProvider>
              <ToastProvider>
                <App container={container} />
              </ToastProvider>
            </ThemeProvider>
          </RecoilRoot>
        </Suspense>
      </QueryClientProvider>
      // {/* </StrictMode> */}
    );
  })
  .catch((err) => console.log(err));