import { useEffect, useRef, useState } from "react";
import { Storage } from "@/apps/Shared/infrastructura/Persistence/Storage";
import { useLocation, useNavigate } from "react-router-dom";
import { backStyle } from "@/apps/Shared/CustomModal/CustomModal";
import Balance from "./Balance/Balance";
import LatestTransactions from "../shared/LatestTransactions/LatestTransactions";
import ManageCards from "./ManageCards/ManageCards";
import { currenciesState } from "../state";
import { useRecoilState } from "recoil";
import { motion, AnimatePresence } from "framer-motion";
import { useTranslation } from "react-i18next";
import UserProfileAndNotifications from "@/apps/Shared/layout/Header/UserProfileAndNotifications/UserProfileAndNotifications";
import { ServicesHome } from "./Services/ServicesHome";
import { ProfileUser, counterSelector, globalAmount } from "./state";
import { CryptoServices } from "../Transactions/Crypto/Services/ServicesSendCrypto";
import { CustomCircle } from "@/apps/Shared/CustomCircle/CustomCircle";
import {
  IconMultipleArrows,
  IconRecharge,
  IconDisableBalance,
} from "@/assets/IconV2/IconsSvg";
import Graph from "@/assets/Graph.png";
import GraphBalances from "./components/GraphBalances/GraphBalances";
import VankLogo from "@/assets/Icon/VankLogo";
import { Sidebar } from "@/apps/Shared/layout/Sildebar/Sidebar";
import ButtonExpand from "@/apps/Shared/layout/components/ButtonExpand/ButtonExpand";
import GraphBalancesMobile from "./components/GraphBalances/GraphBalancesMobile";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import MenuMobile from "./components/MenuMobile/MenuMobile";
import CustomDataLoading from "@/apps/Shared/CustomDataLoading/CustomDataLoading";
import { updateBalnceAtom } from "@/apps/Shared/layout/Header/UserProfileAndNotifications/atoms/updateBalnceAtom";

const currencies = [
  {
    id: 1,
    name: "COP",
  },
  {
    id: 2,
    name: "USD",
  },
];

// Función para formatear el balance como una cadena monetaria
export const formatBalance = (amount: number) => {
  let formattedAmount = amount?.toFixed(2);
  formattedAmount = formattedAmount?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return ` $${formattedAmount}`;
};

const Home: React.FC = ({ toggleOpen }: { toggleOpen?: any }) => {
  const _Storage = new Storage();
  const servicesHome = new ServicesHome();
  const servicesCrypto = new CryptoServices();
  const [updateBanlance, setUpdateBanlance] = useRecoilState(updateBalnceAtom);

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  // Obtener la ubicación actual
  const location = useLocation();
  const [t, i18n] = useTranslation("global");
  const navigate = useNavigate();

  const [activePillar, setActivePillar] = useState(1);
  const [isBalanceAdjustment, setIsBalanceAdjustment] = useState(false);
  const [isLoadingOperations, setIsLoadingOperations] = useState(true);
  const [isLoadingBalances, setIsLoadingBalances] = useState(true);
  const [isLoadingHistory, setIsLoadingHistory] = useState(true);
  const [filteredArray, setFilteredArray] = useState<any>([]);
  const [allfilteredArray, setAllFilteredArray] = useState([]);
  const [listTransactions, setListTransactions] = useState([]);
  // const [showBalance, setShowBalance] = useRecoilState(showBalanceState);
  const [currencyState, setCurrencyState] = useRecoilState(currenciesState);
  const [amount, setAmount] = useRecoilState(globalAmount);

  const [typeColorIconsNav, setTypeColorIconsNav] = useState(0);
  const [strokeColorButtons, setStrokeColorButtons] = useState<number>(0);

  //datacurrencie
  const [objectBalance, setObjectBalance] = useState<any>(null);
  //dataCripto
  const [objectBalanceCripto, setObjectBalanceCripto] = useState<any>(null);

  const [users, setUsers] = useRecoilState<any>(ProfileUser);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [count, setCount] = useState(1);

  const [counter, setCounter] = useRecoilState(counterSelector);

  const [isExpanded, setIsExpanded] = useState(() => {
    const storedState = localStorage.getItem("isHomeExpanded");
    return storedState === "true";
  });

  const ref = useRef(null);

  const amountRef = useRef(null);
  const [width, setWidth] = useState(0);

  const parentDivRef = useRef(null);
  const [parentHeight, setParentHeight] = useState(0);

  // Constante para enfocar el onboarding en el punto deseado
  const step2Ref = useRef<HTMLDivElement>(null);
  const step2RefMobile = useRef<HTMLDivElement>(null);
  const step4Ref = useRef<HTMLDivElement>(null);
  const step4RefMobile = useRef<HTMLDivElement>(null);
  const step9Ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const isMobile = window.innerWidth <= 668; // Detecta si es una pantalla móvil

    const scrollToStep = (stepRef: React.RefObject<HTMLElement>) => {
      if (stepRef.current) {
        stepRef.current.scrollIntoView({
          behavior: "smooth",
          block: "center",
          inline: "nearest",
        });
      }
    };

    if (counter === 4) {
      isMobile ? scrollToStep(step4RefMobile) : scrollToStep(step4Ref);
    } else if (counter === 2) {
      isMobile ? scrollToStep(step2RefMobile) : scrollToStep(step2Ref);
    } else if (counter === 9) {
      scrollToStep(step9Ref); // No necesitas versión mobile aquí
    }
  }, [counter]);

  useEffect(() => {
    // Función para actualizar la altura del parentDiv
    const updateHeight = () => {
      if (parentDivRef.current) {
        const height = parentDivRef.current.offsetHeight;
        setParentHeight(height);
      }
    };

    // Actualizar la altura al cargar el componente
    updateHeight();

    // Escuchar cambios de tamaño en la ventana
    window.addEventListener("resize", updateHeight);

    // Limpiar el event listener al desmontar el componente
    return () => window.removeEventListener("resize", updateHeight);
  }, []);

  useEffect(() => {
    getHistory();
    document.addEventListener("ReloadTransfers", getHistory);
  }, [updateBanlance]);

  useEffect(() => {
    if (
      Object.keys(amount.amount)?.length !== 0 &&
      allfilteredArray?.length === 0
    ) {
      // setIsLoadingBalances(true);
      fetchData();
      return;
    }
  }, [amount]);

  useEffect(() => {
    if (amountRef.current) {
      const elementWidth = amountRef.current.getBoundingClientRect().width;
      setWidth(elementWidth);
    }
  }, [isLoadingOperations, currencyState]);

  const fetchData = async () => {
    try {
      // setIsLoadingBalances(false);
      const response = await servicesHome.getBalancesHome("COP");
      const dataCurrencies = JSON.parse(response.currencies);
      const dataCripto = JSON.parse(response.wallets);

      let parseDataNeworks = null;

      Object.entries(
        dataCripto.RateAmountHistoryCrypto.RateAmountHistoryBalance
      ).map((i: any, _) => (parseDataNeworks = JSON.parse(i[1].NETWORKS)));

      setObjectBalanceCripto(dataCripto); //Hola
      setObjectBalance(dataCurrencies); //  Hola
      setIsLoadingBalances(false);
    } catch (error) {}
  };

  const getHistory = async () => {
    try {
      setIsLoadingHistory(true);
      const response = await servicesHome.getHistory();

      setListTransactions(response);
      setIsLoadingHistory(false);
    } catch (error) {
    } finally {
      setIsLoadingHistory(false);
    }
  };

  const formatName = (fullName: string): string => {
    if (fullName === "") return;
    const [firstName] = fullName?.split(" ");
    return firstName.charAt(0).toUpperCase() + firstName.slice(1).toLowerCase();
  };

  useEffect(() => {
    document.addEventListener("updateBalance", () =>
      handleFilterChange(localStorage.getItem("filterName"))
    );
    document.addEventListener("ReloadAmount", (event: any) =>
      setIsLoadingOperations(event.detail)
    );
  }, []);

  const handleFilterChange = (filterType: string) => {
    if (filterType === "Todos") {
      setFilteredArray(allfilteredArray);
      return;
    }
    const filtered = allfilteredArray.filter(
      (item) => item.type === filterType
    );
    setFilteredArray(filtered);
  };

  const toggleShowBalance = () => {
    setCurrencyState((currentState) => ({
      ...currentState,
      showBalance: !currentState.showBalance,
    }));
  };

  const handleClick = () => {
    setCount((prevCount) => (prevCount < 3 ? prevCount + 1 : 1));
  };

  //funcion para expandir en sidebar mobile
  const toggleExpandHome = () => {
    setIsExpanded(true); // Expande Home
    localStorage.setItem("isHomeExpanded", "true");
    localStorage.setItem("isSidebarExpanded", "false");
    window.dispatchEvent(new Event("storage")); // Dispara evento para sincronizar Sidebar
  };

  const showDollarSign = () => {
    return isLoadingOperations ? "$" : null; // Retorna el signo $ si isLoadingOperations es true
  };

  return (
    <div className=" overflow-hidden touch-action-none  w-full flex flex-col 2xl:py-[32px] xl:py-[32px] lg:py-[32px] md:py-[32px] mobile:py-[0px] 2xl:px-[64px] xl:px-[64px] lg:px-[64px] mobile:px-[7px] tablet:px-[32px] gap-4 ">
      <div className="w-full h-[72px]  justify-center items-start gap-2 inline-flex mobile:hidden 2xl:inline-flex xl:inline-flex lg:inline-flex md:inline-flex">
        <div className="w-full h-full flex-col justify-start items-center gap-2 inline-flex">
          <div className="w-full  h-4 invisible">{t("Vank.Home.Back")}</div>
          <div className="w-full h-12 flex-col justify-end items-start inline-flex ">
            <span className="text-[var(--content-main-black-light)] dark:text-[--color-lightest-white] text-[22px] font-bold leading-none">
              {t("Vank.Home.Welcome.Hello", {
                name: users?.COMPLETENAME
                  ? formatName(users?.COMPLETENAME)
                  : "",
              })}
            </span>
            <span className="text-[var(--content-main-black-light)] text-[14px] dark:text-[--color-lightest-white] font-normal">
              {t("Vank.Home.Welcome.WelcomeMessage")}
            </span>
          </div>
        </div>
        <UserProfileAndNotifications />
      </div>
      {/* mobile */}
      <MenuMobile
        className={`${
          counter === 8 && "z-50"
        } flex items-center justify-between`}
        style={{ pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto" }}
        className2={`${
          counter > 0 ? "absolute" : " fixed z-30"
        } top-0 left-0 w-full`}
      />
      {counter === 8 && (
        <div
          ref={step9Ref}
          className="absolute 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex mobile-375:w-[280px] mobile:w-[230px] min-h-[120px] rounded-[16px] mobile-375:top-[8rem] mobile:top-[11rem] left-[5rem] -translate-y-1/2 flex justify-center items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--bacground-component-module-dark)]   py-4 px-8 z-[101]"
        >
          <div className="absolute -left-3 top-[28px] -translate-y-1/2 min-w-[24px] min-h-[24px] flex justify-center items-center rounded-full bg-[#FFF133]">
            <span className="text-[14px] text-[#000] font-normal">
              {counter}
            </span>
          </div>
          <p className=" text-[18px] text-center dark:text-[var(--content-inner-inner-white-dark)] dark:text-[var(--content-inner-inner-white-light)] font-normal leading-[21.78px]">
            {t("Vank.Home.Onboarding.Hamburguer")}
          </p>
        </div>
      )}

      <div className="w-full  justify-start items-start flex  gap-4 2xl:flex-row xl:flex-row md:flex-col mobile:flex mobile:flex-col ">
        <div className="w-full h-full flex-col justify-start items-start gap-4 inline-flex">
          <div
            className={`relative w-full 2xl:h-[324px] xl:h-[324px] lg:h-[324px] md:h-[324px] sm:h-[324px] mobile:h-auto p-[16px] bg-[var(--bacground-component-module-light)] dark:bg-[--content-main-yellow-dark] rounded-[16px] flex-col justify-between items-start inline-flex ${
              counter === 2 && "z-50"
            }`}
            style={{
              pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
            }}
          >
            {/* saldo */}
            <div className="relative w-full h-[48px] justify-between items-start inline-flex z-10">
              <div className="    h-[48px] flex-col justify-center items-start gap-1 inline-flex ">
                <div className="w-full h-[22px]  justify-start items-center gap-2 inline-flex  leading-none">
                  <motion.div
                    className="min-w-[48px] h-[32px] flex gap-1 items-center transition-all duration-300 overflow-hidden"
                    style={{ width: width ? `max-content` : "max-content" }}
                    // style={{ width: width ? `${width}px` : "max-content" }}
                    initial={{ opacity: 0 }}
                    animate={{ opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.8 }}
                  >
                    {users?.STATUSINCODE === 2 ? (
                      <span
                        className="text-[var(--content-main-black-light)] dark:text-[--color-lightest-white] 
                      text-[22px] 2xl:text-[26px] xl:text-[24px] lg:text-[24px] md:text-[22px] sm:text-[20px] 
                      leading-normal font-semibold"
                      >
                        $ 198,456,546.55
                      </span>
                    ) : !isLoadingOperations ? (
                      <span
                        ref={amountRef}
                        className={`max-w-max transition-all duration-300 text-[var(--content-main-black-light)] dark:text-[--color-lightest-white]  text-[26px] leading-normal font-semibold`}
                      >
                        {!currencyState.showBalance
                          ? "******"
                          : formatBalance(amount?.amount?.amountGlobal)}
                      </span>
                    ) : (
                      <p className="w-[48px] h-8 flex justify-between items-center">
                        <span className="text-base  text-[var(--content-main-black-light)] dark:text-[#C0C0C1] mr-2">
                          {showDollarSign()}
                        </span>
                        <span className="text-[26px]  text-[var(--content-main-black-light)] dark:text-[#C0C0C1] mr-2">
                          --
                        </span>
                      </p>
                    )}
                  </motion.div>

                  <div className="relative w-[81px] h-8 2xl:block xl:block lg:block md:block sm:block mobile:hidden">
                    <button
                      className={`w-[93px] h-[32px] px-4 bg-[var(--background-disabled-input)] border border-transparent dark:bg-[--bacground-sidebar-dark] justify-between items-center ${
                        isDropdownOpen
                          ? "rounded-t-[8px] hover:px-5"
                          : "rounded-[8px] hover:border-[var(--system-yellow-general-yellow)]"
                      } inline-flex cursor-pointer transition-all duration-500`}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      disabled={
                        users?.STATUSINCODE === 2
                          ? true
                          : isLoadingOperations
                          ? true
                          : false
                      }
                    >
                      <div className="text-[var(--content-main-gray-light)] dark:text-[--content-main-gray-dark]  text-sm font-normal leading-7">
                        {amount?.currency}
                      </div>
                      <div className="w-[15px] h-[15px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M13.125 6.9375L9 11.0625L4.875 6.9375"
                            stroke={
                              theme === "dark"
                                ? "var(--color-lightest-white)"
                                : "var(--content-main-black-light)"
                            }
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                    <AnimatePresence>
                      {isDropdownOpen && (
                        <motion.div
                          className="w-[93px] h-[32px] absolute top-8 overflow-hidden rounded-b-[8px]"
                          initial={backStyle.initial}
                          animate={backStyle.animate}
                          exit={backStyle.exit}
                        >
                          {currencies
                            .filter(
                              (currency) => currency.name !== amount?.currency
                            )
                            .map((currency, index) => (
                              <button
                                key={index}
                                className="w-[93px] h-[32px] px-4 hover:px-5 bg-[var(--background-disabled-input)] dark:bg-[--bacground-sidebar-dark] justify-between items-center inline-flex transition-all duration-500 cursor-pointer"
                                onClick={() => {
                                  // setSelectedCurrency(currency.name)
                                  setAmount((amount: any) => ({
                                    ...amount,
                                    currency: currency.name,
                                  }));
                                  setIsDropdownOpen(false);
                                }}
                              >
                                <div className="text-[var(--strokes-pills-default-light)] dark:text-[--content-main-gray-dark] text-sm font-normal leading-7">
                                  {" "}
                                  {currency.name}
                                </div>
                                <div className="w-[15px] h-[15px]" />
                              </button>
                            ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>
                </div>

                <div className="min-w-[135px]  h-5 justify-start items-center gap-1 inline-flex leading-none ">
                  <button
                    className="w-5 h-5 relative cursor-pointer "
                    onClick={toggleShowBalance}
                    disabled={users?.STATUSINCODE === 2}
                  >
                    {!currencyState.showBalance ? (
                      // icono de ojo oculto
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="18"
                        height="18"
                        viewBox="0 0 18 18"
                        fill="none"
                      >
                        <path
                          d="M14.5747 6.65835L15.7414 7.82502C16.0518 8.13729 16.2261 8.55971 16.2261 9.00002C16.2261 9.44033 16.0518 9.86275 15.7414 10.175L13.7164 12.2C12.9192 13.0019 11.9311 13.5879 10.845 13.9028C9.75893 14.2177 8.61062 14.2511 7.50807 14M5.08307 12.8834C4.78731 12.6784 4.50862 12.4499 4.24974 12.2L2.25807 10.175C1.94765 9.86275 1.77342 9.44033 1.77342 9.00002C1.77342 8.55971 1.94765 8.13729 2.25807 7.82502L4.28307 5.80002C5.38812 4.69201 6.84771 4.00805 8.40629 3.86788C9.96486 3.72772 11.5231 4.14029 12.8081 5.03335M7.42474 10.3584C7.087 9.95648 6.91343 9.44192 6.93873 8.91758C6.96403 8.39324 7.18634 7.89779 7.5612 7.5303C7.93606 7.16281 8.43584 6.95038 8.96057 6.93549C9.48531 6.92061 9.99632 7.10437 10.3914 7.45002M16.4081 1.55835L1.44141 16.525"
                          stroke="var(--strokes-pills-default-light)"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    ) : (
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M14.714 6.79412L16.7411 8.82116C17.0536 9.13372 17.2292 9.55765 17.2292 9.99967C17.2292 10.4417 17.0536 10.8656 16.7411 11.1782L14.714 13.2052C13.4638 14.4555 11.7681 15.1578 10 15.1578C8.23189 15.1578 6.5362 14.4555 5.28596 13.2052L3.25892 11.1782C2.94636 10.8656 2.77076 10.4417 2.77076 9.99967C2.77076 9.55765 2.94636 9.13372 3.25892 8.82116L5.28595 6.79412C6.5362 5.54388 8.23189 4.8415 10 4.8415C11.7681 4.8415 13.4638 5.54388 14.714 6.79412Z"
                          stroke="var(--content-main-gray-light)"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M10.0001 12.0827C11.1507 12.0827 12.0834 11.1499 12.0834 9.99935C12.0834 8.84876 11.1507 7.91602 10.0001 7.91602C8.84949 7.91602 7.91675 8.84876 7.91675 9.99935C7.91675 11.1499 8.84949 12.0827 10.0001 12.0827Z"
                          stroke="var(--content-main-gray-light)"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    )}
                  </button>
                  <div className="text-[var(--content-main-gray-light)] dark:text-[--content-main-gray-dark] text-sm font-normal ">
                    {t("Vank.Home.ResumeBalances.BalanceAvailable")}
                  </div>
                </div>
              </div>
              <button
                className={`  2xl:flex xl:flex lg:flex md:flex sm:flex mobile:hidden ${
                  users?.STATUSINCODE === 2 ? "" : "peer"
                } w-[103px] h-[30px] px-6 py-2.5 ${
                  count === 1
                    ? "bg-[--success-backgound-light]"
                    : count === 2
                    ? "bg-[--pending-info-dark]"
                    : "bg-[--error-bg-light]"
                } bg-[--success-backgound-light] rounded-[50px] justify-center items-center gap-1 inline-flex cursor-pointer`}
                id="element-id"
                onClick={handleClick}
                disabled={users?.STATUSINCODE === 2}
              >
                <div className="w-[19px] h-[19px]">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M13.3569 6.64355L6.64355 13.3569"
                      stroke="var(--content-inner-inner-black-light)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                    <path
                      d="M9.32715 6.64355H13.3567V10.6731"
                      stroke="var(--content-inner-inner-black-light)"
                      stroke-width="2"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
                <div className="text-[var(--content-inner-inner-black-light)] text-xs font-medium">
                  12.39%
                </div>
              </button>

              <motion.div
                className="bg-[var(--content-inner-inner-white-light)] dark:bg-[--bacground-sidebar-dark] dark:text-[--content-main-gray-dark] w-[200px] hidden peer-hover:block absolute z-50 -right-52 border-[1px] p-2"
                initial={{ opacity: 0, y: -20 }}
                animate={{ opacity: 1, y: 0 }}
                exit={{ opacity: 0, y: -20 }}
                transition={{ duration: 0.3 }}
              >
                <span className="relative">
                  Aquí va la explicación de esta variación.
                </span>
              </motion.div>
            </div>
            {/* mobile */}
            {!isLoadingOperations && users?.STATUSINCODE === 1 && (
              <div className="flex items-center justify-between w-full 2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden mobile:flex">
                <div className="flex flex-col gap-2">
                  <div className="relative w-[81px] h-8   2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden mobile:block">
                    <button
                      className={`w-[81px] h-8 px-4 bg-[var(--background-disabled-input)] dark:bg-[#2D2E2F] justify-between items-center ${
                        isDropdownOpen ? "rounded-t-[8px]" : "rounded-[8px] "
                      } inline-flex cursor-pointer transition-all duration-500`}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                      disabled={
                        users?.STATUSINCODE === 2
                          ? true
                          : isLoadingOperations
                          ? true
                          : false
                      }
                    >
                      <div className="text-[var(--content-main-gray-light)] dark:text-[--content-main-gray-dark] text-sm font-normal leading-7">
                        {amount?.currency}
                      </div>
                      <div className="w-[15px] h-[15px]">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="18"
                          height="18"
                          viewBox="0 0 18 18"
                          fill="none"
                        >
                          <path
                            d="M13.125 6.9375L9 11.0625L4.875 6.9375"
                            stroke={
                              theme === "dark"
                                ? "var(--color-lightest-white)"
                                : "var(--content-main-black-light)"
                            }
                            stroke-width="1.5"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          />
                        </svg>
                      </div>
                    </button>
                    <AnimatePresence>
                      {isDropdownOpen && (
                        <motion.div
                          className="w-full max-h-min absolute top-8 overflow-hidden rounded-b-[8px]"
                          initial={backStyle.initial}
                          animate={backStyle.animate}
                          exit={backStyle.exit}
                        >
                          {currencies
                            .filter(
                              (currency) => currency.name !== amount?.currency
                            )
                            .map((currency, index) => (
                              <button
                                key={index}
                                className="w-[81px] h-8 px-4 bg-[var(--background-disabled-input)] dark:bg-[#2D2E2F] justify-between items-center inline-flex cursor-pointer"
                                onClick={() => {
                                  // setSelectedCurrency(currency.name)
                                  setAmount((amount: any) => ({
                                    ...amount,
                                    currency: currency.name,
                                  }));
                                  setIsDropdownOpen(false);
                                }}
                              >
                                <div className="text-[var(--strokes-pills-default-light)] dark:text-[--content-main-gray-dark] text-sm font-normal leading-7">
                                  {" "}
                                  {currency.name}
                                </div>
                                <div className="w-[15px] h-[15px]" />
                              </button>
                            ))}
                        </motion.div>
                      )}
                    </AnimatePresence>
                  </div>

                  <button
                    className={`2xl:hidden xl:hidden lg:hidden md:hidden sm:hidden mobile:flex ${
                      users?.STATUSINCODE === 2 ? "" : "peer"
                    } py-[5px] pl-[8px] pr-[12px] ${
                      count === 1
                        ? "bg-[--success-backgound-light]"
                        : count === 2
                        ? "bg-[--pending-info-dark]"
                        : "bg-[--error-bg-light]"
                    } bg-[--success-backgound-light] rounded-[50px] justify-center items-center gap-1 inline-flex cursor-pointer`}
                    id="element-id"
                    onClick={handleClick}
                    disabled={users?.STATUSINCODE === 2}
                  >
                    <div className="w-[19px] h-[19px]">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 20 20"
                        fill="none"
                      >
                        <path
                          d="M13.3569 6.64355L6.64355 13.3569"
                          stroke="var(--content-inner-inner-black-light)"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                        <path
                          d="M9.32715 6.64355H13.3567V10.6731"
                          stroke="var(--content-inner-inner-black-light)"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </div>
                    <div className="text-[var(--content-inner-inner-black-light)] text-xs font-medium">
                      12.39%
                    </div>
                  </button>
                </div>

                <div className="w-[160px] relative h-[150px]   2xl:hidden xl:hidden lg:hidden md:hidden sm:flex mobile:flex justify-end items-start ">
                  {users?.STATUSINCODE === 2 ? (
                    <img src={Graph} alt="" />
                  ) : (
                    objectBalance != null &&
                    objectBalanceCripto && (
                      <GraphBalancesMobile
                        amountCurrency={
                          amount?.amount?.currencies?.currencie?.amount
                        }
                        amountCripto={amount?.amount?.wallets?.wallets?.amount}
                      />
                    )
                  )}
                </div>

                <div className="bg-[var(--content-inner-inner-white-light)] w-[200px]  hidden peer-hover:block absolute z-50 -right-52 border-[1px] p-2 ">
                  <span className="relative">
                    Aquí va la explicación de esta variación.
                  </span>
                </div>
              </div>
            )}
            {/* end movile */}
            {/* grafica */}
            {(isLoadingOperations || isLoadingBalances) && users?.STATUSINCODE === 1 ? (
              <div className="w-full lg:h-full mobile:h-[287px] flex justify-center items-center">
                <CustomDataLoading />
              </div>
            ) : (
              <div className=" w-full h-[133px]  overflow-hidden justify-start 2xl:justify-center items-center gap-4 2xl:gap-10 flex 2xl:flex-row xl:flex-row lg:flex-row md:flex-row sm:flex-row mobile:flex-col">
                <motion.div
                  className="relative  2xl:block xl:block lg:block md:block sm:block mobile:hidden  h-[123px] flex justify-center items-center"
                  initial={{ opacity: 0, scale: 0.2 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, scale: 0.2 }}
                  transition={{ duration: 0.3 }}
                >
                  {users?.STATUSINCODE === 2 ? (
                    <img src={Graph} alt="" />
                  ) : (
                    objectBalance != null &&
                    objectBalanceCripto && (
                      <GraphBalances
                        amountCurrency={
                          amount?.amount?.currencies?.currencie?.amount
                        }
                        // amountCripto={amount?.amount?.wallets?.wallets?.amount}
                        amountCripto={amount?.amount?.wallets?.wallets?.amount}
                      />
                    )
                  )}
                </motion.div>

                <div className="w-full h-[123px]  flex-col  pr-[16px]  justify-center items-start gap-[12px] inline-flex ">
                  <div className="w-full h-3 justify-between  items-center inline-flex">
                    <div className="w-[78px] h-[12px] flex items-center gap-2">
                      <div className="w-3 h-3 bg-[--new-color-accounts] rounded-full" />
                      <span className="text-right text-[var(--content-main-gray-light)] dark:text-[#C0C0C1]  text-[12px] font-normal ">
                        {t("Vank.Home.ResumeBalances.Balance.Accounts")}
                      </span>
                    </div>
                    <span className="text-[var(--content-disabled-input)] text-[12px]  font-normal ">
                      /
                    </span>
                    {users?.STATUSINCODE === 2 ? (
                      <span className=" text-right text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-semibold ">
                        $65,256,986.00 COP
                      </span>
                    ) : !isLoadingOperations ? (
                      <span className="text-right text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-semibold ">
                        <span className="-mx-1 "></span>
                        {formatBalance(
                          amount?.amount?.currencies?.currencie?.amount
                        )}{" "}
                        {amount?.amount?.currencies?.currencie?.currency}
                      </span>
                    ) : (
                      <span className="w-[120px] min-h-3 flex justify-between items-center bg-[var(--background-layaout-light)] dark:bg-[#212222] bg-opacity-45 animate-pulse rounded-md px-4">
                        <span className="text-base text-[var(--content-main-black-light)] dark:text-[#C0C0C1]  mr-2">
                          $
                        </span>
                        {[1, 2, 3, 4].map((_, index) => (
                          <div className="w-4 h-full" key={index}>
                            <IconDisableBalance />
                          </div>
                        ))}
                      </span>
                    )}
                  </div>

                  {/* <div className="w-full h-px bg-[var(--strokes-separator-separator-light)]" /> */}

                  {/* <div className="w-full h-3 justify-between items-center inline-flex">
                  <div className="w-[78px] h-[12px] flex items-center gap-2">
                    <div className="w-3 h-3 bg-[--new-color-wallet] rounded-full" />
                    <span className="text-right text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-[12px] font-normal">
                      {t("Vank.Home.ResumeBalances.Balance.Wallet")}
                    </span>
                  </div>
                  <span className="text-[var(--content-disabled-input)] text-[12px] font-normal ">
                    /
                  </span>
                  {users?.STATUSINCODE === 2 ? (
                    <span className="max-w-max text-right text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-semibold ">
                      $92,256,986.00 COP
                    </span>
                  ) : !isLoadingOperations ? (
                    <span className="max-w-max text-right text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-base font-semibold ">
                      <span className="-mx-1 ">$</span>
                      {formatBalance(
                        amount?.amount?.wallets?.wallets?.amount
                      )}{" "}
                      {amount?.amount?.wallets?.wallets?.wallet}
                    </span>
                  ) : (
                    <span className="w-[120px] min-h-3 flex justify-between items-center bg-[var(--background-layaout-light)] dark:bg-[#212222] bg-opacity-45 animate-pulse rounded-md px-4">
                      <span className="text-base text-[var(--content-main-black-light)] dark:text-[#FFFFFF] mr-2">
                        $
                      </span>
                      {[1, 2, 3, 4].map((_, index) => (
                        <div className="w-4 h-full" key={index}>
                          <svg
                            version="1.1"
                            id="Capa_1"
                            xmlns="http://www.w3.org/2000/svg"
                            xmlnsXlink="http://www.w3.org/1999/xlink"
                            viewBox="0 0 290.658 290.658"
                            xmlSpace="preserve"
                            fill="#000000"
                          >
                            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                            <g
                              id="SVGRepo_tracerCarrier"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></g>
                            <g id="SVGRepo_iconCarrier">
                              {" "}
                              <g>
                                {" "}
                                <g>
                                  {" "}
                                  <rect
                                    y="139.474"
                                    className="fill:#030303;"
                                    width="290.658"
                                    height="11.711"
                                  ></rect>{" "}
                                </g>{" "}
                              </g>{" "}
                            </g>
                          </svg>
                        </div>
                      ))}
                    </span>
                  )}
                </div> */}

                  <div className="w-full h-px bg-[var(--strokes-separator-separator-light)] dark:bg-[#212222]" />

                  <div className="w-full h-3 justify-between items-center inline-flex opacity-45">
                    <div className="w-[78px] h-[12px] flex items-center gap-2">
                      <div className="w-3 h-3 bg-[--background-disabled-button] rounded-full" />
                      <span className="text-right text-[var(--content-main-gray-light)] dark:text-[#C0C0C1]  text-[12px] font-normal">
                        {t("Vank.Home.ResumeBalances.Balance.tarjeta")}
                      </span>
                    </div>
                    <span className="text-[var(--content-disabled-input)] dark:text-[#C0C0C1] text-[12px] font-normal">
                      /
                    </span>
                    {!isLoadingOperations ? (
                      <span className="min-w-[165px] text-right text-[var(--content-main-gray-light)] dark:text-[#C0C0C1] text-xs font-normal ">
                        {i18n.language === "es" ? (
                          <>
                            {
                              t(
                                "Vank.Home.ResumeBalances.Balance.VankCards.Text"
                              ).split("estarán")[0]
                            }
                            estarán
                            <br />
                            {
                              t(
                                "Vank.Home.ResumeBalances.Balance.VankCards.Text"
                              ).split("estarán")[1]
                            }
                          </>
                        ) : (
                          t("Vank.Home.ResumeBalances.Balance.VankCards.Text") // Frase completa en inglés sin el salto de línea
                        )}
                      </span>
                    ) : (
                      <span className="w-[120px] min-h-3 flex justify-between items-center bg-[var(--background-layaout-light)] dark:bg-[#212222] animate-pulse rounded-md px-4">
                        <span className="text-base text-[var(--content-main-black-light)] dark:text-[#FFFFFF] mr-2">
                          $
                        </span>
                        {[1, 2, 3, 4].map((_, index) => (
                          <div className="w-4 h-full" key={index}>
                            <IconDisableBalance />
                          </div>
                        ))}
                      </span>
                    )}
                  </div>
                </div>
              </div>
            )}

            {/* Acciones */}
            <div className="w-full h-[40px]  justify-start items-start gap-4 inline-flex 2xl:flex xl:flex lg:flex md:flex sm:hidden mobile:hidden ">
              {/* <button
                onMouseEnter={() => setTypeColorIconsNav(1)}
                onMouseLeave={() => setTypeColorIconsNav(0)}
                className="hover:bg-black group w-full h-10  py-3.5 bg-[--branding-brand-yellow-light] rounded-full justify-center items-center gap-2 inline-flex cursor-pointer"
                onClick={() =>
                  document.dispatchEvent(new Event("ReceiveModal"))
                }
                disabled={counter === 2}
              >
                <span className="max-w-[78px] text-[var(--content-inner-inner-black-light)] text-base font-medium group-hover:text-[var(--branding-brand-yellow-light)] ">
                  {t("Vank.Home.ResumeBalances.Actions.Recharge")}
                </span>
                <div className="w-5 h-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M6.6333 13.5335L13.7 6.4668M6.6333 13.5335H10.875M6.6333 13.5335V9.2918"
                      stroke={`${
                        typeColorIconsNav == 0
                          ? "var(--content-inner-inner-black-light)"
                          : typeColorIconsNav == 1
                          ? "var(--branding-brand-yellow-light)"
                          : "var(--content-inner-inner-black-light)"
                      }`}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </button> */}

              <button
                onMouseEnter={() => setTypeColorIconsNav(2)}
                onMouseLeave={() => setTypeColorIconsNav(0)}
                className="hover:bg-black group w-full  h-10  py-3.5 bg-[--branding-brand-yellow-light] rounded-[999px] justify-center items-center gap-2 inline-flex cursor-pointer transition-colors ease-out  duration-300"
                onClick={() => document.dispatchEvent(new Event("SendModal"))}
                disabled={counter === 2}
              >
                <span className="max-w-[58px] text-[var(--content-inner-inner-black-light)] text-lg font-medium group-hover:text-[var(--branding-brand-yellow-light)]">
                  {t("Vank.Home.ResumeBalances.Actions.Send")}
                </span>
                <div className="w-5 h-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    viewBox="0 0 20 20"
                    fill="none"
                  >
                    <path
                      d="M13.5332 6.4668L6.46655 13.5335M13.5332 6.4668H9.29155M13.5332 6.4668V10.7085"
                      stroke={`${
                        typeColorIconsNav == 0
                          ? "var(--content-inner-inner-black-light)"
                          : typeColorIconsNav == 2
                          ? "var(--branding-brand-yellow-light)"
                          : "var(--content-inner-inner-black-light)"
                      }`}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </button>

              {/* <button
                onMouseEnter={() => setTypeColorIconsNav(3)}
                onMouseLeave={() => setTypeColorIconsNav(0)}
                className="w-full hover:bg-black group  h-10   py-3.5 bg-[--branding-brand-yellow-light] rounded-[999px] justify-center items-center gap-2 inline-flex cursor-pointer"
                onClick={() => navigate("/convert")}
                disabled={counter === 2}
              >
                <span className="max-w-[81px] text-[var(--content-inner-inner-black-light)] text-lg font-medium group-hover:text-[var(--branding-brand-yellow-light)]">
                  {t("Vank.Home.ResumeBalances.Actions.Convert")}
                </span>
                <div className="w-5 h-5">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="21"
                    height="20"
                    viewBox="0 0 21 20"
                    fill="none"
                  >
                    <path
                      d="M6.16675 12.4286L8.66675 15V5M14.5001 7.57143L12.0001 5V15"
                      stroke={`${typeColorIconsNav == 0
                          ? "var(--content-inner-inner-black-light)"
                          : typeColorIconsNav == 3
                            ? "var(--branding-brand-yellow-light)"
                            : "var(--content-inner-inner-black-light)"
                        }`}
                      stroke-width="1.5"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>
                </div>
              </button> */}
            </div>

            {counter === 2 && (
              <div
                ref={step2Ref}
                className="2xl:block xl:block lg:hidden md:hidden sm:hidden mobile:hidden  absolute w-[372px] h-[120px] rounded-[16px] -top-4 left-[103%] flex justify-end items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--bacground-component-module-dark)] py-4 pl-8 pr-4 z-50"
              >
                <div className="absolute -left-2 top-1/2 -translate-y-1/2 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                  <span className="text-[14px] text-[#000] font-normal">
                    {counter}
                  </span>
                </div>
                <p className="text-[18px] text-left text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] leading-[21.78px] font-normal">
                  {t("Vank.Home.Onboarding.Two")}
                </p>
              </div>
            )}
            {/* mensaje onboarding 2 */}
            {counter === 2 && (
              <div
                ref={step2RefMobile}
                className="2xl:hidden xl:hidden lg:flex md:flex sm:flex mobile:flex absolute w-[80%] flex justify-center items-center z-50  mx-auto left-0 right-0"
                style={{ bottom: `calc(${parentHeight}px + -10rem)` }}
              >
                <div className="relative 2xl:hidden xl:hidden lg:flex md:flex sm:flex mobile:flex relative w-[372px] h-[125px] rounded-[16px] flex justify-end items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--bacground-component-module-dark)] py-4 px-4 z-[50]">
                  <div className="absolute -top-[15px] mx-auto left-0 right-0 w-[24px] h-[24px] flex justify-center items-center rounded-full bg-[var(--branding-brand-yellow-light)]">
                    <span className="text-[14px] text-[#000] font-normal">
                      {counter}
                    </span>
                  </div>
                  <p className="text-[18px] text-center text-[var(--content-main-black-light)] dark:text-[var(--content-main-black-dark)] leading-[21.78px] font-normal">
                    {t("Vank.Home.Onboarding.Two")}
                  </p>
                </div>
              </div>
            )}
          </div>

          {/* {objectBalance != null && objectBalanceCripto != null && ( */}
          <Balance
            updateStatuses={() => fetchData()}
            objectBalanceCripto={objectBalanceCripto}
            objectBalance={objectBalance}
            filteredArray={[""]}
            setIsBalanceAdjustment={setIsBalanceAdjustment}
            activePillar={activePillar}
            setActivePillar={setActivePillar}
            handleFilterChange={handleFilterChange}
            isLoadingBalances={isLoadingBalances}
          />
          {/* // )} */}
        </div>

        <div
          className="relative  w-full h-full 2xl:flex-col xl:flex-col md:flex-col-reverse mobile:flex-col-reverse 2xl:mb-0 xl:mb-0 lg:mb-0 tablet:mb-0 mobile:mb-4 justify-start items-start gap-4 inline-flex "
          ref={ref}
        >
          <ManageCards />
          {/* {listTransactions?.length > 0 && ( */}
          <LatestTransactions
            listTransactions={listTransactions}
            isLoadingHistory={isLoadingHistory}
            className="w-full 2xl:h-[398px] xl:h-[398px] lg:h-[398px] md:h-[520px] sm:h-[398px] mobile:h-10  mobile:flex mobile:items-center mobile:justify-center rounded-2xl  "
          />
          {/* // )} */}
          <div
            className={`max-w-max 2xl:absolute xl:absolute lg:absolute md:absolute sm:fixed mobile:fixed 2xl:top-[44%] xl:top-[44%] lg:top-[44%] md:top-[44%] sm:top-[80%] mobile-375:top-[85%] mobile:top-[75%]  flex  flex-col gap-4 items-end  2xl:-right-[48px] xl:-right-[48px] lg:-right-[48px] md:-right-[48px] sm:-right-[0px] mobile:right-[0px] [transform:translate(0,-50%)] ${
              counter === 10000 &&
              "bg-[#FFF] dark:bg-[--content-main-yellow-dark] z-50 rounded-full"
            }`}
            style={{
              pointerEvents: users?.STATUSINCODE === 2 ? "none" : "auto",
            }}
          >
            {/* <button
              onMouseEnter={() => setStrokeColorButtons(1)}
              onMouseLeave={() => setStrokeColorButtons(0)}
              className="2xl:flex xl:flex lg:flex md:flex sm:hidden mobile:hidden overflow-hidden cursor-pointer group hover:max-w-max transition-width duration-300 max-w-max hover:pl-4 hover:bg-[var(--content-inner-inner-black-light)] rounded-full  h-10 bg-blac justify-end items-center gap-2 hidden pointer-events-none"
            >
              <span className="group-hover:block hidden text-[var(--branding-brand-yellow-light)]">
                {t("Vank.Home.BtnAction.paymentLot")}
              </span>
              <CustomCircle
                icon={
                  <IconMultipleArrows
                    stroke={
                      strokeColorButtons == 1
                        ? "var(--branding-brand-yellow-light)"
                        : "var(--content-inner-inner-black-light)"
                    }
                  />
                }
                moreStyle="group-hover:bg-[var(--content-inner-inner-black-light)] w-10 h-full bg-[var(--branding-brand-yellow-light)] "
              />
            </button> */}

            {/* <button
              onMouseEnter={() => setStrokeColorButtons(2)}
              onMouseLeave={() => setStrokeColorButtons(0)}
              className=" overflow-hidden cursor-pointer group hover:max-w-max transition-width duration-300  hover:bg-[var(--content-inner-inner-black-light)] rounded-full max-w-max h-10 hover:pl-4 bg-blac justify-end items-center gap-2 hidden pointer-events-none"
            >
              <span className="group-hover:block hidden text-[--branding-brand-yellow-light]">
                {t("Vank.Home.BtnAction.RechargeMoney")}
              </span>
              <CustomCircle
                icon={
                  <IconRecharge
                    stroke={
                      strokeColorButtons == 2
                        ? "var(--branding-brand-yellow-light)"
                        : "var(--content-inner-inner-black-light)"
                    }
                  />
                }
                moreStyle="group-hover:bg-[var(--content-inner-inner-black-light)] w-10 h-full bg-[var(--branding-brand-yellow-light)] "
              />
            </button> */}

            {/* {counter === 4 && (
              <div
                ref={step4Ref}
                className="absolute 2xl:flex xl:flex lg:flex md:flex sm:hidden mobile:hidden min-w-[377px] min-h-[120px] rounded-[16px] top-1/2 -translate-y-1/2 right-[140%] flex justify-center items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--bacground-component-module-dark)] py-4 pl-4 pr-8 z-50"
              >
                <div className="absolute -right-2 top-1/2 -translate-y-1/2 min-w-[24px] min-h-[24px] flex justify-center items-center rounded-full bg-[#FFF133]">
                  <span className="text-[14px] text-[#000] font-normal">
                    {counter}
                  </span>
                </div>
                <p className=" text-[18px] text-left dark:text-[var(--content-inner-inner-white-dark)] text-[var(--content-inner-inner-black-dark)] font-normal leading-[21.78px]">
                  {t("Vank.Home.Onboarding.Four")}
                </p>
              </div>
            )} */}

            {/* {counter === 4 && (
              <div
                ref={step4RefMobile}
                className="absolute  2xl:hidden xl:hidden lg:hidden md:hidden sm:flex mobile:flex mobile-375:min-w-[300px] mobile:min-w-[240px] min-h-[120px] rounded-[16px] top-1/2 -translate-y-1/2 right-[140%] flex justify-center items-center bg-[var(--bacground-component-module-light)] dark:bg-[var(--bacground-component-module-dark)] py-4 pl-4 pr-8 z-50"
              >
                <div className="absolute -right-2 top-1/2 -translate-y-1/2 min-w-[24px] min-h-[24px] flex justify-center items-center rounded-full bg-[#FFF133]">
                  <span className="text-[14px] text-[#000] font-normal">
                    {counter}
                  </span>
                </div>
                <p className=" text-[18px] text-left text-[var(--content-inner-inner-black-light)] dark:text-[var(--content-inner-inner-white-dark)] font-normal leading-[21.78px]">
                  {t(
                    "Este es la quick action, desde este botón podrás ir directamente a la sección de recargar fondos mediante código QR."
                  )}
                </p>
              </div>
            )} */}
          </div>
        </div>
      </div>
      {/* <Onboarding /> */}
    </div>
  );
};

export default Home;
