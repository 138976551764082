export const environment = {
  name: "vank production",
  host: "localhost",
  port: "8000",
  protocolo: "http",
  pathName: "vank",

  url_servers: {
    url_orchertractor:
      "https://egzjcqsq7s.us-east-2.awsapprunner.com/orchestrator",
    // url_orchertractor: "https://egzjcqsq7s.us-east-2.awsapprunner.com/binanceorchestrator",

    url_users: "https://ay2zyxm6ee.us-east-2.awsapprunner.com/users",

    url_library: "https://gd3jyakmcm.us-east-2.awsapprunner.com/library",

    url_operation:
      "https://pnr6f93wnz.us-east-2.awsapprunner.com/operations/secure/v1",

    url_usersAWS: "https://ay2zyxm6ee.us-east-2.awsapprunner.com/users",

    url_prints: "https://dcm6k2zkzn.us-east-2.awsapprunner.com",

    url_fiat: "https://te7mgdecwk.us-east-2.awsapprunner.com",
    url_upload_excel:"https://nvlzhnmj-3033.use2.devtunnels.ms",
    url_batch_template: "https://templatesvank.s3.us-west-2.amazonaws.com",
  },

  url_externas: {
    url_realTimeValue:
      "https://api.coingecko.com/api/v3/simple/price?ids=bitcoin,ethereum,usd-coin,tether&vs_currencies=usd",

    url_binance: "",

    url_incode: "https://w3grhmvstt.us-east-2.awsapprunner.com/incode",
  },
};
