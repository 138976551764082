import { jwtDecode } from "jwt-decode";
import { StorageService } from "../../../services/StorageServiceVanKPay";
import { decryptInfo } from "../../../utils/Utilfunctions";

export class ServiceDecodiToken {

  //Decodificar Token  
  public async decodeToken() {
    
    const token = await StorageService.get("token");
    const tokenDecode = jwtDecode(token);

    return tokenDecode;
  }

  //Retornar virtual Email
  public async getVirtualEmail() {
    
    const tokenDecode = await this.decodeToken();

    return tokenDecode["user"].USERDATA.VIRTUALEMAIL;
  }

  //Retornar Email
  public async getEmail() {
   
    const tokenDecode = await this.decodeToken();
  
    return tokenDecode["user"].USERDATA.EMAIL;
  }

  public async getPhone() {
   
    const tokenDecode = await this.decodeToken();
   
  
    return tokenDecode["user"].USERDATA.PHONE;
  }

  public async getAllData(){

    const tokenDecode = await this.decodeToken();
    return tokenDecode["user"].USERDATA;

  }

  public async getVankId(){
    const tokenDecode = await this.decodeToken();
    return tokenDecode["user"].USERDATA.VANKID;
  }

  public async CONFIGUSERS(){
    const tokenDecode = await this.decodeToken();
    return tokenDecode["user"].USERDATA.CONFIGUSERS;
  }

  public async getCommission(){
    let co =await this.CONFIGUSERS()
    // let descrip=parseFloat(atob(co.FEE )??(co.FEE ));
    let descrip=parseFloat(decryptInfo(co.FEE))
    return descrip;
  }


}
