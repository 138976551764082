import { useEffect, useState } from "react";
import { HeaderModal } from "../../Home/Modales/HeaderModal/HeaderModal";
import { format } from "date-fns";
import "./style.css";
import SharedButtons from "../SharedButtons/SharedButtons";
import {
  IconClose,
  IconInfo,
  IcontReloj,
  Vector,
} from "@/assets/IconV2/IconsSvg";
import { ServicesLibrary } from "../../Transactions/VankPay/service/ServicesLibrary";
import { ServiceDecodiToken } from "../../Transactions/VankPay/service/ServiceDecodiToken";
import { formatBalance } from "../../Home/Home";
import { IndexedDBLocalDropdownRepository } from "@/apps/Shared/infrastructura/Persistence/IndexedDBLocalDropdownRepository";
import { CustomButtonsForms } from "../CustomButtonsForms/CustomButtonsForms";
import { useTranslation } from "react-i18next";

import { Tooltip } from "react-tooltip";

//import { formtDatemodal } from "@/apps/Vank/Page/utils/Utilfunctions";
import {
  capitalizeName,
  formtDateModal,
  TranslatedDate,
} from "@apps/Vank/Page/utils/Utilfunctions";
import { CustomCircle } from "@/apps/Shared/CustomCircle/CustomCircle";
import { motion } from "framer-motion";
import CustomLoading from "@/apps/Shared/CustomLoading/CustomLoading";
import { useTheme } from "@/Context/UseContext/ThemeContext";

const ModalInfoTransaction = ({
  isExpenseModalOpen,
  onClick,
  data,
  onclickCancel,
  loadingButtonsCancel
}: {
  isExpenseModalOpen?: any;
  onClick?: () => void;
  data?: any;
  onclickCancel?: () => void;
  loadingButtonsCancel?: boolean
}) => {
  const cache = new IndexedDBLocalDropdownRepository();
  const libraryService = new ServicesLibrary();
  const tokenService = new ServiceDecodiToken();
  const [arrayCryptos, setArrayCriptos] = useState<any>([]);
  const [arrayCurrency, setArrayCurrency] = useState<any>([]);
  const [dataUser, setDataUser] = useState<any>({});
  const [infoCrypto, setInfoCrypto] = useState<any>({});
  const [urlImg, setUrlImg] = useState<string>();
  const [t, i18n] = useTranslation("global");

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  const getDataCripto = async () => {
    try {
      const dataCrypto = await libraryService.getDataCripto(null);

      const infoCrypto = dataCrypto.body.filter((item: any) =>
        item.ASSET.includes(data.ASSET)
      );

      if (infoCrypto.length != 0) {
        setInfoCrypto(infoCrypto[0]);
      } else {
        const dataCountry = await libraryService.getDataCountry(null);


        const infoCountry = dataCountry.body.filter((item: any) =>
          item.ISO.includes(data.ASSET)
        );



        setInfoCrypto(infoCountry[0]);
      }

      const dataToken = await tokenService.getVirtualEmail();

      setDataUser(dataToken);
    } catch (error) {

    }
  };

  const getDataUser = async () => {
    const data = await tokenService.getVirtualEmail();

    setDataUser(data);
  };

  useEffect(() => {
    getDataUser();
  }, []);

  const getDataEntries = (object: any): any => {
    let resul = "";
    const entriesCurrencie = Object.entries(object.value);

    entriesCurrencie.map((item: any, index) => {
      if (item[1].ISO == data.ASSET) {
        resul = item[1].IMGURL;
      } else {
        if (item[1].ASSET == data.ASSET) {
          resul = item[1].IMGURL;
        }
      }
    });

    return resul;
  };

  useEffect(() => {
    const fechtData = async () => {
      const dataCurrencie: any = await cache.get("localCurrency");
      const dataCrypto: any = await cache.get("localWallet");

      let result =
        getDataEntries(dataCurrencie) == ""
          ? getDataEntries(dataCrypto)
          : getDataEntries(dataCurrencie);

      setUrlImg(result);
    };

    fechtData();
  }, []);

  return (
    <motion.div
      className={`${data.STATUSORDER == 3 || data.STATUSORDER == 4
        ? "2xl:border-2 xl:border-2 lg:border-2 tablet:border-2 mobile:border-0 border-[#FAA6A6]"
        : "border-none"
        } bg-[var(--bacground-component-module-light)] dark:bg-[#1A1A1B]
                2xl:w-[500px] xl:w-[500px] lg:w-[500px] md:w-[500px] sm:w-[500px] mobile:w-full bottom-0 
                2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute
                2xl:rounded-2xl xl:rounded-2xl lg:rounded-2xl tablet:rounded-2xl mobile:rounded-t-2xl  flex flex-col 2xl:gap-6 xl:gap-6 lg:gap-6 tablet:gap-6 mobile:gap-3 h-auto max-h-[88vh]`}
      initial={isExpenseModalOpen ? { y: "100%" } : { y: 0 }} // Solo en móvil comienza fuera de la pantalla
      animate={{ y: 0 }} // Siempre termina en su posición original
      exit={isExpenseModalOpen ? { y: "100%" } : { y: 0 }} // Sale hacia abajo solo en móvil
      transition={{ duration: 0.5 }} // Duración y suavizado de la transición
    >
      <div className=" flex flex-col">
        <div className="2xl:py-4 xl:py-4 lg:py-4 tablet:py-4 mobile:py-3 2xl:px-[24px] xl:px-[24px] lg:px-[24px] tablet:px-[24px] mobile:px-[16px]">
          <HeaderModal
            headerStyle="w-full justify-center items-center"
            headerHeight=""
            textStyle="textitle"
            text={`${data.ACTION == "INPUT"
              ? t(
                "Page.shared.ModalInfoTransaction.ModalInfoTransaction.VankpayShippingApproved"
              )
              : t(
                "Page.shared.ModalInfoTransaction.ModalInfoTransaction.VankpayShippingApproved"
              )
              }`}
            onClick={onClick}
          />
        </div>
        <div className="bg-[#F1F1F1] dark:bg-[#212222] h-[1px] w-full "></div>
      </div>

      <div className="p-4 2xl:gap-6 xl:gap-6 lg:gap-6 tablet:gap-6 mobile:gap-3 flex flex-col overflow-auto">
        <div className="w-full flex justify-center flex-col items-center 2xl:gap-2 xl:gap-2 lg:gap-2 tablet:gap-2 mobile:gap-[0.1rem] 2xl:-mt-4 xl:-mt-4 lg:-mt-4 tablet:-mt-4 mobile:-mt-4">
          <CustomCircle
            moreStyle={` ${data.STATUSORDER == 1
              ? "bg-[#6AD67B]"
              : data.STATUSORDER == 2
                ? "bg-[#FFD595]"
                : data.STATUSORDER == 3
                  ? "bg-[#FCC3C3]"
                  : "bg-[#FCC3C3]"
              } 
                      2xl:w-[50px] xl:w-[50px] lg:w-[50px] tablet:w-[50px] mobile:w-[42px] mb-2
                      2xl:h-[50px] xl:h-[50px] lg:h-[50px] tablet:h-[50px] mobile:h-[42px] `}
            icon={
              data.STATUSORDER == 1 ? (
                <Vector />
              ) : data.STATUSORDER == 2 ? (
                <IcontReloj className="h-auto" />
              ) : data.STATUSORDER == 3 ? (
                <IconClose className="w-[90px] h-[90px]" fill="#2D2E2F" />
              ) : (
                <IconClose className="w-[90px] h-[90px]" fill="#2D2E2F" />
              )
            }
          />

          <p className="text-[var(--content-main-black-light)] text-center font-semibold dark:text-[#FFFFFF] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px]">
            {data.STATUSORDER == 1 ? (
              <div>
                <span className="2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                  {t(
                    "ApprovedTransaction.ApprovedTransaction.Approvedtransaction"
                  )}
                </span>
                <span className="2xl:block xl:block lg:block tablet:block mobile:hidden">
                  {t(
                    "ApprovedTransaction.ApprovedTransaction.Approvedtransaction2"
                  )}
                </span>
              </div>
            ) : data.STATUSORDER == 2 ? (
              <div>
                <span className="2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                  {t(
                    "ApprovedTransaction.ApprovedTransaction.Pendingtransaction"
                  )}
                </span>
                <span className="2xl:block xl:block lg:block tablet:block mobile:hidden">
                  {t(
                    "ApprovedTransaction.ApprovedTransaction.Pendingtransaction2"
                  )}
                </span>
                <span className="2xl:block xl:block lg:block tablet:block mobile:hidden 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal">
                  {t(
                    "ApprovedTransaction.ApprovedTransaction.Pendingtransaction3"
                  )}
                </span>
              </div>
            ) : data.STATUSORDER == 3 ? (
              t("ApprovedTransaction.ApprovedTransaction.canceledtransaction")
            ) : (
              t("ApprovedTransaction.ApprovedTransaction.Failedtransaction")
            )}
          </p>
          <p
            className={`${data.STATUSORDER == 4 ? "block" : "hidden"
              } w-[80%] text-center text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-[500] leading-[0.8rem] dark:text-[#FFFFFF]`}
          >
            {t("ApprovedTransaction.ApprovedTransaction.textError")}
          </p>
          <p className="text-[var(--content-main-gray-light)] text-center 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
            {TranslatedDate(data.GENERATEDDATE, i18n.language)}
          </p>
          <p className="text-[var(--content-main-gray-light)] text-center 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
            {t(
              "Transactions.Fiat.components.ModalSuccessFiat.ModalSuccessFiat.IdTransaction"
            )}{" "}
            <span className="font-semibold">{data.CODERECEIPT}</span>
          </p>
        </div>
        <div className="flex gap-1 items-center justify-center ">
          <img src={data.IMGURL} alt="" className="h-[28px] w-[28px] me-1" />
          <p className="font-[600] text-lg dark:text-[#FFFFFF] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px]">
            {formatBalance(data.AMOUNT)}
          </p>
          <p className="dark:text-[#FFFFFF] font-[600] 2xl:text-[20px] xl:text-[20px] lg:text-[20px] tablet:text-[20px] mobile:text-[16px]">
            {data.ASSET}
          </p>
        </div>

        <div className="flex flex-col 2xl:gap-[16px] xl:gap-[16px] lg:gap-[16px] tablet:gap-[16px] mobile:gap-[10px]">
          <section className="w-full bg-[var(--background-layaout-light)] dark:bg-[#2D2E2F] 2xl:p-4 xl:p-4 lg:p-4 tablet:p-4 mobile:p-2 rounded-xl flex flex-col 2xl:gap-2 xl:gap-2 lg:gap-2 tablet:gap-2 mobile:gap-[0.1rem]">
            <div className="section-info">
              <p
                className={`text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1] 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex `}
              >
                {t(
                  "Page.shared.ModalInfoTransaction.ModalInfoTransaction.textSendTo"
                )}
              </p>

              {data.STATUSORDER == 1 || data.STATUSORDER == 2 ? (
                <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1] 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden">
                  {t(
                    "Page.shared.ModalInfoTransaction.ModalInfoTransaction.textSendTo2"
                  )}
                </p>
              ) : (
                <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1] 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden">
                  {t(
                    "Page.shared.ModalInfoTransaction.ModalInfoTransaction.textSendTo"
                  )}
                </p>
              )}
              <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] dark:text-[#FFFFFF] font-[600]">
                {formatBalance(data.AMOUNT)} {data.ASSET}
              </p>
            </div>

            <div className="section-info">
              <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                {t(
                  "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Paymentorigin"
                )}
              </p>
              <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF]">
                {data.ASSET} Vank Wallet
              </p>
            </div>

            <div className="section-info 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden">
              <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                <div className="">
                  <span className="2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                    {t(
                      "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sendto"
                    )}
                  </span>

                  <span className="2xl:block xl:block lg:block tablet:block mobile:hidden">
                    {t(
                      "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sendto2"
                    )}
                  </span>
                </div>
                {/* {data.ACTION == "OUTPUT" ? (
                  <div className="">
                    <span className="2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                      {t(
                        "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sendto"
                      )}
                    </span>

                    <span className="2xl:block xl:block lg:block tablet:block mobile:hidden">
                      {t(
                        "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sendto2"
                      )}
                    </span>
                  </div>
                ) : (
                  <div>
                    <span className="2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block">
                      {t(
                        "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sentby"
                      )}
                    </span>

                    <span className="2xl:block xl:block lg:block tablet:block mobile:hidden">
                      {t(
                        "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sendto"
                      )}
                    </span>
                  </div>
                )} */}
              </p>
              <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF] 2xl:w-[15rem] xl:w-[15rem] lg:w-[13rem] mobile-375:w-[12rem] min-[360px]:w-[12rem] mobile:w-[11rem] break-words text-end">
                {data.ACTION === "OUTPUT"
                  ? data?.COMPLETENAMERECEPTOR?.includes("@")
                    ? capitalizeName(data?.COMPLETENAMERECEPTOR)
                    : capitalizeName(
                      `${data?.COMPLETENAMERECEPTOR?.split(" ")[0]} ${data?.SURNAMERECEPTOR?.split(" ")[0] || ""
                      }`
                    )
                  : data?.NAMEREMISOR?.includes("@")
                    ? capitalizeName(data?.NAMEREMISOR)
                    : capitalizeName(
                      `${data?.NAMEREMISOR?.split(" ")[0]} ${data?.SURNAMEEMISOR?.split(" ")[0] || ""
                      }`
                    )}
              </p>
            </div>

            <div className="section-info 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex">
              {" "}
              {/**este dato solo se muestra en mobile */}
              <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                {t(
                  "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Sendto"
                )}
              </p>
              <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] dark:text-[#FFFFFF] ">
                {data?.TOSUBACCOUNTID}
              </p>
            </div>

            <div className="section-info 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden">
              <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                {t(
                  "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Transactionalfee"
                )}
              </p>
              <p className="text-[var(--content-main-black-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF]">
                {t(
                  "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Free"
                )}
              </p>
            </div>

            <div
              className={`${data.DESCRIPTION ? "" : "hidden"} section-info `}
            >
              <p className="text-[var(--content-main-gray-light)] 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#C0C0C1]">
                {t(
                  "Vank.Transaction.Money.SendFiat.components.Components.ModalResumenFiat.text10"
                )}
                :
              </p>
              <p
                data-tooltip-id="description-tooltip"
                data-tooltip-content={data.DESCRIPTION} // Pasa la descripción completa al tooltip
                className={`max-h-[40px] tablet:w-[15rem] mobile:w-[14rem] overflow-y-auto overflow-x-hidden text-[var(--content-main-black-light)] text-end 2xl:text-[14px] xl:text-[14px] lg:text-[14px] tablet:text-[14px] mobile:text-[12px] font-normal dark:text-[#FFFFFF]`}
                // style={{
                //   display: "-webkit-box", // Usar box model para flexbox
                //   WebkitBoxOrient: "vertical", // Orientación vertical del box
                //   WebkitLineClamp: 2, // Limita a 2 líneas
                //   whiteSpace: "normal", // Permitir que el texto se divida en varias líneas
                // }}
              >
                {data.DESCRIPTION}
              </p>
              <Tooltip
                id="description-tooltip"
                place="top"
                className="max-w-xs hidden" // Define el ancho máximo del tooltip
                style={{
                  background:
                    theme === "dark"
                      ? "#1A1A1B"
                      : "var(--background-layaout-light)",
                  color:
                    theme === "dark"
                      ? "#FFFFFF"
                      : "var(--content-inner-inner-black-light)",
                  boxShadow: "-2px 1px 10px 0px rgba(0, 0, 0, 0.07)",
                  borderRadius: "8px",
                  zIndex: 1000, // Asegúrate de que esté por encima de otros elementos
                  opacity: 1, // Asegúrate de que la opacidad sea 1
                }}
              />
            </div>
          </section>

          {data.USUARIOVANK === "EXTERNO" && data.STATUSORDER == 2 && (
            <div className="flex items-center  px-4 py-2 rounded-xl 2xl:gap-4 xl:gap-4 lg:gap-4 tablet:gap-2 mobile:gap-2 bg-[#FFF3DB]">
              <IconInfo
                id="my-anchor-element"
                className="cursor-pointer  peer 2xl:w-8 xl:w-8 lg:w-8 tablet:w-12 mobile:w-8 2xl:h-8 xl:h-8 lg:h-8 tablet:h-12 mobile:h-8 "
                fill="#000"
              />

              <p className="text-[14px] text-[var(--content-inner-inner-black-light)] 2xl:block xl:block lg:block tablet:block mobile:hidden">
                {t(
                  "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Text1"
                )}
              </p>

              <p className="text-[12px] text-[var(--content-inner-inner-black-light)] 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:block leading-4">
                {t(
                  "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Text2mobile"
                )}
              </p>
            </div>
          )}

          <section
            className={`${data.STATUSORDER == 3 || data.STATUSORDER == 4
              ? "hidden"
              : "flex 2xl:flex xl:flex lg:flex tablet:flex mobile:hidden"
              } `}
          >
            <SharedButtons
              TypeAction={data?.ACTION}
              CODERECEIPT={data?.CODERECEIPT}
              GENERATEDBY={dataUser}
              URL={data.IMGURL}
              email={data.TOSUBACCOUNTID}
            />
          </section>

          <section className="2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex">
            <SharedButtons
              TypeAction={data?.ACTION}
              CODERECEIPT={data?.CODERECEIPT}
              GENERATEDBY={dataUser}
              URL={data.IMGURL}
              email={data.TOSUBACCOUNTID}
            />
          </section>
          {data.STATUSORDER == 1 ? (
            <button
              className="flex 2xl:py-[10px] xl:py-[10px] lg:py-[10px] tablet:py-[10px] mobile:py-[7px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] text-[var(--content-inner-inner-black-light)]  bg-[var(--branding-brand-yellow-light)] transition duration-300 hover:text-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] dark:hover:text-[#2D2E2F] dark:hover:bg-[#FFFFFF] group"
              onClick={onClick}
            >
              <p className="flex justify-center items-center gap-[8px]  text-[16px] font-semibold  ">
                {t(
                  "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn"
                )}
              </p>
            </button>
          ) : data.STATUSORDER == 2 ? (
            <div className="w-full flex justify-center items-center">
              <section className="2xl:block xl:block lg:block tablet:block mobile:hidden w-full">
                {loadingButtonsCancel ?
                  <CustomLoading/>
                  :
                  <CustomButtonsForms
                    type="button"
                    labelClear={t(
                      "Page.shared.ModalInfoTransaction.ModalInfoTransaction.Cancel"
                    )}
                    label={t(
                      "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn"
                    )}
                    onClickClear={onclickCancel}
                    onClickContinue={onClick}
                  />
                }

              </section>

              <button
                className=" w-full 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex flex 2xl:py-[10px] xl:py-[10px] lg:py-[10px] tablet:py-[10px] mobile:py-[7px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] text-[var(--content-inner-inner-black-light)]  bg-[var(--branding-brand-yellow-light)] transition duration-300 hover:text-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] dark:hover:text-[#2D2E2F] dark:hover:bg-[#FFFFFF] group"
                onClick={onClick}
              >
                <p className="flex justify-center items-center gap-[8px]  text-[16px] font-semibold  ">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn2"
                  )}
                </p>
              </button>
            </div>
          ) : (
            <div className="w-full">
              <button
                className=" w-full 2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex flex 2xl:py-[10px] xl:py-[10px] lg:py-[10px] tablet:py-[10px] mobile:py-[7px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] text-[var(--content-inner-inner-black-light)]  bg-[var(--branding-brand-yellow-light)] transition duration-300 hover:text-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] dark:hover:text-[#2D2E2F] dark:hover:bg-[#FFFFFF] group"
                onClick={onClick}
              >
                <p className="flex justify-center items-center gap-[8px]  text-[16px] font-semibold  ">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn2"
                  )}
                </p>
              </button>

              <button
                className="w-full 2xl:block xl:block lg:block tablet:block mobile:hidden flex 2xl:py-[10px] xl:py-[10px] lg:py-[10px] tablet:py-[10px] mobile:py-[7px] px-[32px] justify-center items-center gap-[8px] rounded-[999px] text-[var(--content-inner-inner-black-light)]  bg-[var(--branding-brand-yellow-light)] transition duration-300 hover:text-[var(--branding-brand-yellow-light)] hover:bg-[var(--background-tab-selected)] dark:hover:text-[#2D2E2F] dark:hover:bg-[#FFFFFF] group"
                onClick={onClick}
              >
                <p className="flex justify-center items-center gap-[8px]  text-[16px] font-semibold  ">
                  {t(
                    "Vank.Transaction.Money.Tables.TableTransactionFiat.ItemSummary.Btn"
                  )}
                </p>
              </button>
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default ModalInfoTransaction;
