import React from 'react'
import './CustomLoading.css'

const CustomLoading = () => {
    return (
        <div className="loade">
            <div className="bar"></div>
            <div className="bar"></div>
            <div className="bar"></div>
        </div>


    )
}

export default CustomLoading