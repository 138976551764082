import { ServicesUser } from "@/apps/services/ServicesUser";
import { IndexedDBLocalDropdownRepository } from "./IndexedDBLocalDropdownRepository";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";
import { jwtDecode } from "jwt-decode";
import { Services } from "./services/services";

interface DropDownConfig {
  id: string;
  localRepository: IndexedDBLocalDropdownRepository;
  remoteRepository: () => Promise<any>;
  updateEvent: string;
}

export class CacheDropDowns {
  config: DropDownConfig[];

  constructor() {
    const services = new Services();

    this.config = [
      {
        id: "localWallet",
        localRepository: new IndexedDBLocalDropdownRepository(),
        remoteRepository: () => services.getLibraryWallet(),
        updateEvent: "localWalletUpdated",
      },
      {
        id: "localCountryView",
        localRepository: new IndexedDBLocalDropdownRepository(),
        remoteRepository: () => services.getLibraryCountryView(),
        updateEvent: "localCountryViewUpdated",
      },
      {
        id: "localCurrency",
        localRepository: new IndexedDBLocalDropdownRepository(),
        remoteRepository: () => services.getLibraryCurrency(),
        updateEvent: "localCurrencyUpdated",
      },
      // {
      //   id: "localTown",
      //   localRepository: new IndexedDBLocalDropdownRepository(),
      //   remoteRepository: () => services.getLibraryTown(),
      //   updateEvent: "localTownUpdated",
      // },
      {
        id: "localRegion",
        localRepository: new IndexedDBLocalDropdownRepository(),
        remoteRepository: () => services.getLibraryRegion(),
        updateEvent: "localRegionUpdated",
      },
      // Añadir más configuraciones aquí si es necesario
    ];
  }

  async cache() {
    const localCache = async (config: DropDownConfig) => {
      // const localRepository = await config.localRepository.searchAll(config.id);
      // if (localRepository.length === 0) {
        const data = await config.remoteRepository();

        await config.localRepository.save({
          key: config.id,
          data,
        });

        document.dispatchEvent(new CustomEvent(config.updateEvent));
        return;
      // }
      document.dispatchEvent(new CustomEvent(config.updateEvent));
    };
    this.config.forEach((config) => localCache(config));
  }

  async checkTokenExpiration(token) {
    try {
      const decodedToken = jwtDecode(token);
      const currentTime = Date.now() / 1000; // Convertir milisegundos a segundos
      return decodedToken.exp < currentTime;
    } catch (error) {
      // console.error("Error decoding token:", error);
      return true; // Si hay un error al decodificar, asumimos que el token es inválido o ha expirado
    }
  }

  async getUser() {
    try {
      const services = new ServicesUser();
      const token = await StorageService.get("token");
      await new IndexedDBLocalDropdownRepository().remove("user");
      if (token) {
        const Exp = await this.checkTokenExpiration(token);
        if (!Exp) {
          const hasUser = await services.getUser();
          
          if (hasUser?.statusCode === 200 && hasUser?.body === null) {
            throw new Error("not-token");
          }
          // hasUser[0].STATUSINCODE = 1
         
          await new IndexedDBLocalDropdownRepository().save({
            key: "user",
            data: hasUser,
          });
          return;
        }
        throw new Error("session-expired");
      }
      throw new Error("not-token");
    } catch (error) {
      
      throw error;
    }
  }
}
