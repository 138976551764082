import { AnimatePresence, motion } from 'framer-motion';
import { useState } from 'react'
import CreditCard1 from '@assets/img/imgCard/CreditCard1.png'
import CreditCard2 from '@assets/img/imgCard/CreditCard2.png'
import CreditCard3 from '@assets/img/imgCard/CreditCard3.png'
import visa1 from '@assets/img/imgCard/visa1.png'
import visa2 from '@assets/img/imgCard/visa2.png'
import { useTranslation } from 'react-i18next';
import NextView from '../NextView/NextView';
import { useTheme } from '@/Context/UseContext/ThemeContext';

const cardData = [
    {
        id: 1,
        backgroundImage: CreditCard1,
        background: '#D9D9D9',
        textColor: 'var(--content-inner-inner-black-light)',
        standar: {
            img: visa2,
            label: 'Standar',
            background: 'var(--content-inner-inner-black-light)',
            textColor: 'var(--branding-brand-yellow-light)'
        },
        price: '$15,595.15 USD',
        cardNumber: '9876 5432 1098 7654',
        owner: 'Maria Lopez',
        expiry: '11/24',
        cvv: '456',
        visible: {
            color: "var(--content-inner-inner-black-light)"
        }
    },

    {
        id: 2,
        backgroundImage: CreditCard2,
        background: 'rgba(191, 183, 78, 0.50)',
        textColor: 'var(--content-inner-inner-white-light)',
        standar: {
            img: visa1,
            label: 'Premium',
            background: 'var(--content-inner-inner-white-light)',
            textColor: 'var(--content-inner-inner-black-light)'
        },
        price: '$15,595.15 USD',
        cardNumber: '1234 5678 9012 3456',
        owner: 'Johanna Sanchez',
        expiry: '12/25',
        cvv: '123',
        visible: {
            color: "var(--content-inner-inner-white-light)"
        }
    },

    {
        id: 3,
        backgroundImage: CreditCard3,
        background: 'rgba(45, 46, 47, 0.50)',
        textColor: 'var(--content-inner-inner-white-light)',
        standar: {
            img: visa1,
            label: 'Premium',
            background: 'var(--content-inner-inner-white-light)',
            textColor: 'var(--content-inner-inner-black-light)'
        },
        price: '$15,595.15 USD',
        cardNumber: '1234 5678 9012 3456',
        owner: 'Carlos Perez',
        expiry: '12/25',
        cvv: '123',
        visible: {
            color: "var(--content-inner-inner-white-light)"
        }
    },
];

const variants = {
    enter: (direction: number) => {
        return {
            x: direction > 0 ? 1000 : -1000,
            // opacity: 0,
            // scale: 0.8,
        };
    },
    center: {
        zIndex: 1,
        x: 0,
        opacity: 1,
        scale: 1,
        transition: {
            x: { type: 'spring', stiffness: 200, damping: 30 }
        }
    },
    exit: (direction: number) => {
        return {
            zIndex: 0,
            x: direction < 0 ? 1000 : -1000,
            // opacity: 0,
            // scale: 0.8,
            transition: {
                x: { type: 'spring', stiffness: 200, damping: 30 }
            }
        };
    }
};

const SliderCard = ({ setShow }: { setShow: any }) => {

    const [current, setCurrent] = useState(0);
    const [t, i18n] = useTranslation("global");
    const [showDetails, setShowDetails] = useState(cardData.map(() => false));
    const [page, setPage] = useState(0);
    const [direction, setDirection] = useState(0);


    const { theme, toggleDarkMode } = useTheme(); // Usa el contexto


    const paginate = (newDirection: number) => {
        setDirection(newDirection);
        setPage(page + newDirection);
        setCurrent((prev) => (prev + newDirection + cardData?.length) % cardData?.length);
    };

    const paginateIndi = (newIndex: number) => {
        const newDirection = newIndex > current ? 1 : -1;
        setDirection(newDirection);
        setCurrent(newIndex);
      };

    // const nextCard = () => {
    //     setCurrent((prev) => (prev === cardData?.length - 1 ? 0 : prev + 1));
    // };

    // const prevCard = () => {
    //     setCurrent((prev) => (prev === 0 ? cardData?.length - 1 : prev - 1));
    // };

    const toggleDetails = (index) => {
        setShowDetails((prev) => {
            const newDetails = [...prev];
            newDetails[index] = !newDetails[index];
            return newDetails;
        });
    };


    // Función para enmascarar el número de cuenta
    const maskAccountNumber = (number: string): string => {
        const lastFourDigits = number.slice(-4); // Obtener los últimos 4 dígitos
        return `**** **** **** ${lastFourDigits}`; // Crear la cadena enmascarada
    };

    return (
        <div>
        <motion.div className="2xl:flex xl:flex lg:flex tablet:flex mobile:hidden relative w-full min-w-[530px] h-[324px] p-4 bg-[var(--bacground-component-module-light)] dark:bg-[#1A1A1B] rounded-[16px] flex flex-col justify-center items-center gap-4"
            initial={{ opacity: 0, scale: 0.98 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.98 }}
            transition={{ duration: 0.3 }}
        >

            <div className="w-full min-w-[530px] h-[17px] justify-center items-center inline-flex ">
                <span className="text-[var(--content-main-gray-light)] dark:text-[#FFFFFF] text-sm font-normal underline cursor-pointer" onClick={() => setShow(true)}>{t("Vank.Home.ManageCards.SliderCard.Title")}</span>
            </div>

            <div className="relative w-full min-w-[498px] min-h-[218px] justify-between items-center inline-flex overflow-x-hidden">
                {/* left */}
                <button className={`w-[40px] h-[40px] justify-center items-center rotate-180 inline-flex cursor-pointer ${current > 0 ? 'opacity-100' : 'opacity-0'} transition-opacity duration-300`}
                    onClick={() => paginate(-1)}
                    disabled={current > 0 ? false : true}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M8.54102 5.41602L13.1243 9.99935L8.54102 14.5827" stroke={theme === "dark" ? "#FFFFFF" : "var(--content-inner-inner-black-light)"}
                           
                        stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>

                {/* card */}
                <AnimatePresence custom={direction} initial={false} mode='popLayout'>
                    {cardData.map((card, index) =>
                        index === current ? (
                            <motion.div className='min-w-[372px] min-h-[218px] flex items-center justify-center gap-8'
                                key={index}
                                custom={direction}
                                variants={variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                style={{
                                    backgroundImage: `url(${card.backgroundImage})`,
                                    backgroundSize: 'cover',
                                    backgroundPosition: 'center',
                                    color: card.textColor,
                                }}
                            >
                                <div
                                    key={card.id}
                                    className="w-[340px] h-[186px] p-4 flex-col justify-between items-start gap-2.5 inline-flex rounded-2xl z-30"

                                >

                                    <div className="w-full h-[42px] justify-between items-center inline-flex overflow-hidden">
                                        <div className="min-w-[113px] h-5 justify-start items-center gap-1 inline-flex">
                                            <span className="text-xs font-normal ">{showDetails[index] ? t("Vank.Home.ManageCards.SliderCard.Card.HideDetails") : t("Vank.Home.ManageCards.SliderCard.Card.ShowDetails")}</span>
                                            <button className="w-5 h-5 relative" onClick={() => toggleDetails(index)}>
                                                {showDetails[index] ? (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                                                        <path d="M14.5747 6.65835L15.7414 7.82502C16.0518 8.13729 16.2261 8.55971 16.2261 9.00002C16.2261 9.44033 16.0518 9.86275 15.7414 10.175L13.7164 12.2C12.9192 13.0019 11.9311 13.5879 10.845 13.9028C9.75893 14.2177 8.61062 14.2511 7.50807 14M5.08307 12.8834C4.78731 12.6784 4.50862 12.4499 4.24974 12.2L2.25807 10.175C1.94765 9.86275 1.77342 9.44033 1.77342 9.00002C1.77342 8.55971 1.94765 8.13729 2.25807 7.82502L4.28307 5.80002C5.38812 4.69201 6.84771 4.00805 8.40629 3.86788C9.96486 3.72772 11.5231 4.14029 12.8081 5.03335M7.42474 10.3584C7.087 9.95648 6.91343 9.44192 6.93873 8.91758C6.96403 8.39324 7.18634 7.89779 7.5612 7.5303C7.93606 7.16281 8.43584 6.95038 8.96057 6.93549C9.48531 6.92061 9.99632 7.10437 10.3914 7.45002M16.4081 1.55835L1.44141 16.525" stroke={card.visible.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                ) : (
                                                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none">
                                                        <path d="M14.714 7.1286L16.7411 9.15563C17.0536 9.46819 17.2292 9.89212 17.2292 10.3341C17.2292 10.7762 17.0536 11.2001 16.7411 11.5127L14.714 13.5397C13.4638 14.7899 11.7681 15.4923 10 15.4923C8.23189 15.4923 6.5362 14.7899 5.28596 13.5397L3.25892 11.5127C2.94636 11.2001 2.77076 10.7762 2.77076 10.3341C2.77076 9.89212 2.94636 9.46819 3.25892 9.15563L5.28595 7.1286C6.5362 5.87835 8.23189 5.17597 10 5.17597C11.7681 5.17597 13.4638 5.87835 14.714 7.1286Z" stroke={card.visible.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        <path d="M10.0003 12.4167C11.1509 12.4167 12.0837 11.4839 12.0837 10.3333C12.0837 9.18274 11.1509 8.25 10.0003 8.25C8.84973 8.25 7.91699 9.18274 7.91699 10.3333C7.91699 11.4839 8.84973 12.4167 10.0003 12.4167Z" stroke={card.visible.color} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                    </svg>
                                                )}
                                            </button>
                                        </div>

                                        <div className="w-[210px] h-[42px] flex-col justify-center items-end gap-1 inline-flex">
                                            <div className="w-[71.19px] min-h-[23px] justify-end items-center inline-flex">
                                                <img src={card.standar.img} alt="" className='w-full h-full object-contain' />
                                            </div>
                                            <div className="w-[63px] h-[15px] px-2 py-0.5 rounded-xl justify-center items-center inline-flex"
                                                style={{
                                                    background: card.standar.background,
                                                    color: card.standar.textColor,
                                                }}
                                            >
                                                <span className="text-[9px] font-semibold  uppercase tracking-wide">{card.standar.label}</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="w-full h-[66px] flex-col justify-start items-start gap-6 inline-flex overflow-hidden">
                                        <span className="w-[280px] h-[17px] text-[24px]  font-normal leading-[29.05px]">{showDetails[index] ? card.price : '$****'}</span>
                                        <div className="max-w-max min-h-5 flex justify-center items-center gap-2">
                                            <span className='text-[16px] font-normal'>{showDetails[index] ? card.cardNumber : maskAccountNumber(card.cardNumber)}</span>
                                            {showDetails[index] && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none">
                                                    <g clip-path="url(#clip0_511_63514)">
                                                        <path d="M16.625 2.16675C17.5915 2.16675 18.375 2.91294 18.375 3.83341V13.8334C18.375 14.7539 17.5915 15.5001 16.625 15.5001H14.875V17.1667C14.875 18.0872 14.0915 18.8334 13.125 18.8334H4.375C3.4085 18.8334 2.625 18.0872 2.625 17.1667V7.16675C2.625 6.24627 3.4085 5.50008 4.375 5.50008H6.125V3.83341C6.125 2.91294 6.9085 2.16675 7.875 2.16675H16.625ZM13.125 7.16675H4.375V17.1667H13.125V7.16675ZM8.75 13.0001C9.23326 13.0001 9.625 13.3732 9.625 13.8334C9.625 14.2937 9.23326 14.6667 8.75 14.6667H7C6.51676 14.6667 6.125 14.2937 6.125 13.8334C6.125 13.3732 6.51676 13.0001 7 13.0001H8.75ZM16.625 3.83341H7.875V5.50008H13.125C14.0915 5.50008 14.875 6.24627 14.875 7.16675V13.8334H16.625V3.83341ZM10.5 9.66675C10.9833 9.66675 11.375 10.0398 11.375 10.5001C11.375 10.9275 11.0372 11.2797 10.602 11.3278L10.5 11.3334H7C6.51676 11.3334 6.125 10.9603 6.125 10.5001C6.125 10.0727 6.46279 9.72049 6.89796 9.67235L7 9.66675H10.5Z" fill={card.visible.color} />
                                                    </g>
                                                    <defs>
                                                        <clipPath id="clip0_511_63514">
                                                            <rect width="21" height="20" fill="white" transform="translate(0 0.5)" />
                                                        </clipPath>
                                                    </defs>
                                                </svg>
                                            )}
                                        </div>
                                    </div>

                                    <div className="w-full h-[35px] justify-between items-center inline-flex overflow-hidden">
                                        <div className="w-[158px] h-8 flex-col justify-start items-start inline-flex leading-none ">
                                            <span className="w-full text-[14px] font-semibold  uppercase tracking-wide ">{card.owner}</span>
                                            <span className="w-full  text-xs font-normal ">{t("Vank.Home.ManageCards.SliderCard.Card.Owner")}</span>
                                        </div>

                                        <div className="w-[70px] h-[35px] flex-col justify-start items-start inline-flex leading-none">
                                            <span className="w-8 h-5 text-base font-normal">{showDetails[index] ? card.expiry : '****'}</span>
                                            <span className="w-[70px] text-xs font-normal">{t("Vank.Home.ManageCards.SliderCard.Card.Expiration")}</span>
                                        </div>

                                        <div className="w-[60px] h-[35px] flex-col justify-start items-start inline-flex leading-none">
                                            <span className="w-6 h-5 text-base font-normal">{showDetails[index] ? card.cvv : '***'}</span>
                                            <span className="w-[25px] text-xs font-normal">CVV</span>
                                        </div>
                                    </div>
                                </div>
                            </motion.div>
                        ) : null
                    )}
                </AnimatePresence>

                {/* rigth */}
                <button className={`w-[40px] h-[40px] justify-center items-center inline-flex ${current === cardData?.length - 1 ? 'opacity-0' : 'opacity-100'} cursor-pointer`}
                    onClick={() => paginate(1)}
                    disabled={current === cardData?.length - 1 ? true : false}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                        <path d="M8.54102 5.41602L13.1243 9.99935L8.54102 14.5827" stroke={theme === "dark" ? "#FFFFFF" : "var(--content-inner-inner-black-light)"} stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </svg>
                </button>

            </div>

            <div className="w-full h-[48px] justify-center items-center gap-2 inline-flex">
                {cardData.map((card, index) => (
                    <button key={index} className={`h-2 ${current === index ? 'w-4 bg-[var(--content-main-black-light)] dark:bg-[#FFFFFF]' : 'w-2 bg-[var(--content-main-gray-light)] dark:bg-[#2D2E2F]'} rounded cursor-pointer transition-all duration-500`}
                        onClick={() => paginateIndi(index)}
                    />
                ))}
            </div>

        </motion.div>

        <div className='2xl:hidden xl:hidden lg:hidden tablet:hidden mobile:flex'>
            <NextView />
        </div>
        </div>
    )
}

export default SliderCard