import { useTheme } from "@/Context/UseContext/ThemeContext";
import React from "react";

export const IconLogout = ({
  className,
  stroke,
}: {
  className?: any;
  stroke?: string;
}) => {
  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="28"
      height="28"
      viewBox="0 0 28 28"
      fill="none"
      className={className}
    >
      <path
        d="M15.5 18V20C15.5 20.7956 15.1839 21.5587 14.6213 22.1213C14.0587 22.6839 13.2957 23 12.5 23H8.5C7.70435 23 6.94129 22.6839 6.37868 22.1213C5.81607 21.5587 5.5 20.7956 5.5 20V8C5.5 7.20435 5.81607 6.44129 6.37868 5.87868C6.94129 5.31607 7.70435 5 8.5 5H12.5C13.2957 5 14.0587 5.31607 14.6213 5.87868C15.1839 6.44129 15.5 7.20435 15.5 8V10"
        // stroke="var(--content-main-black-light)"
        stroke={
          theme === "dark"
            ? "var(--color-lightest-white)"
            : "var(--content-main-black-light)"
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M22.5 13.7305H10.5"
        // stroke={stroke ?? "var(--content-main-black-light)"}
        stroke={
          theme === "dark"
            ? "var(--color-lightest-white)"
            : "var(--content-main-black-light)"
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.9 10.1299L22.5 13.7299L18.9 17.3299"
        // stroke={stroke ?? "var(--content-main-black-light)"}
        stroke={
          theme === "dark"
            ? "var(--color-lightest-white)"
            : "var(--content-main-black-light)"
        }
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
