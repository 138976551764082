import { useState } from 'react';
import CustomButton from '../../../../../Shared/CustomButton/CustomButton'
import { IconCopyV2 } from '@/assets/IconV2/IconsSvg';
import { AlertCopied } from '../AlertCopied/AlertCopied';
export const ButtonCopy = ({textCopy,classNameBtnCopy,text,clasNameIcon}:{textCopy?:any,classNameBtnCopy?:any,text?:any,clasNameIcon?:string}) => {
    const [copied,setCopied]=useState(false);
    //funcion que permite copiar el texto
    const copyToClipboard = () => {
        setCopied(true)
        navigator.clipboard.writeText(textCopy).then(() => {
            
        }).catch(err => {
            console.error('Error al copiar el texto al portapapeles:', err);
        });
        setTimeout(()=>{
            setCopied(false)
        },900)
        
    };

    return (

        <CustomButton className={classNameBtnCopy}
            label={<span className="flex gap-2 relative"onClick={() => copyToClipboard()}>
            <p className="">{text} </p><IconCopyV2 className={clasNameIcon}/> 
            {copied && <AlertCopied moreStyle='-top-4'/>}
             </span> }  />

    )
}
