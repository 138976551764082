import {atom} from "recoil";

export const postDataUserNotFound=atom({
    key:"valueDataUserNotFound",
    default:{
    EMAIL:"",
    ASSET:"",
    AMOUNT:'',
    CODEASSET:''
    }
})