import { motion } from "framer-motion";
import "./CustomButton.css"
import React from "react";

const CustomButton = ({
  className,
  children,
  label,
  onclick,
  rest,
  disabled,
  onMouseEnter,
  onMouseLeave,
  type,
}: {
  className?: string;
  children?: any;
  label?: any;
  style?: any;
  onclick?: () => void;
  rest?: any;
  disabled?: boolean;
  onMouseEnter?:()=>void;
  onMouseLeave?:()=>void;
  type?:string  
}) => {
  return (
    <motion.button
      whileTap={{ scale: 0.95 }}
      className={className}
      onClick={onclick}
      {...rest}
      disabled={disabled}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      type={type}
    >
      {children || label}
    </motion.button>
  );
};

export default CustomButton;
