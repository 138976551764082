import { useTheme } from "@/Context/UseContext/ThemeContext";
import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { formatTime } from "../RootVank";
import ServiceSidebar from "@/apps/Shared/layout/Sildebar/ServiceSidebar";
import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";

const ModalInactAndExpi = ({
  type,
  isClose,
  setIsOpenExpiry,
}: {
  type: string;
  isClose: any;
  setIsOpenExpiry: any;
}) => {
  const [t] = useTranslation("global");

  const [isLoading, setIsLoading] = useState(false);
  const serviceSidebar = new ServiceSidebar();
  // Inicializa el estado, poniendo true si "Inactive" no existe en localStorage
  const [isInactive, setIsInactive] = useState(false);
  const [count, setCount] = useState(0);

  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  const handleClear = async () => {
    await StorageService.delete("token");
    await StorageService.delete("verify");
    localStorage.removeItem("ip");
    localStorage.removeItem("ipTimestamp");
    localStorage.removeItem("id");
  };

  useEffect(() => {
    if (type && isClose) {
      // Mostramos la animación de carga después de 5 segundos
      setTimeout(() => {
        setIsLoading(true);
        setTimeout(() => {
          serviceSidebar
            .LogOutUser()
            .then(async () => {
              handleClear()
              await StorageService.set("inactivity", true);
              setIsOpenExpiry(false)
              window.location.href = "/";
            })
            .catch((error) => {
              console.error("Error during logout:", error);
              localStorage.setItem("counter", "1");
            })
            .finally(async () => {
              setIsLoading(false); // Desactiva el estado de carga, tanto si la operación es exitosa como si falla
            });
        }, 1000);
      }, 1000);
      localStorage.setItem("counter", "1");
    }
  }, [type, isClose]);

  useEffect(() => {
    const handleIsInact = async () => {
      const isInact = await StorageService.get("inactivity"); // Llama al método de forma síncrona si es posible
      setIsInactive(isInact);
    };

    const handleBeforeUnload = async () => {
      // Obtener el valor actual del contador desde localStorage y convertirlo a número
      const savedCount = parseInt(localStorage.getItem("counter") || "1", 10);
      // Alternar entre 0 y 1 dependiendo del valor guardado
      const newCount = savedCount === 1 ? 0 : 1;
      setCount(newCount);

      // Guardar el nuevo valor en localStorage
      localStorage.setItem("counter", newCount.toString());
      if (newCount === 1) {
        await StorageService.set("inactivity", false);
      }
    };

    window.addEventListener("beforeunload", handleBeforeUnload);

    handleIsInact();
  }, []);

  return (
    <motion.div
      onClick={(e) => e.stopPropagation()}
      className="relative w-[342px] min-h-[142px] bg-[--color-lightest-white]  dark:bg-[#1A1A1B] cursor-default flex flex-col justify-start items-center gap-4 p-4 rounded-[16px] overflow-hidden"
      initial={{
        scale: 0,
      }}
      animate={{
        scale: 1,
        transition: {
          duration: 0.3,
        },
      }}
      exit={{
        scale: 0,
      }}
    >
      <div className={`w-full pl-0.5 items-center justify-between inline-flex`}>
        <span className="text-center text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-xl font-semibold">
          {type === "Inactive"
            ? "Inactividad Detectada"
            : t("Share.Expire.Title")}
        </span>
        <button
          className={`w-7 h-7 rounded-[500px] justify-center items-center gap-2.5 flex hover:dark:bg-[var(--content-main-black-light)] transition-all duration-300 ${
            !isInactive ? "hidden" : ""
          }`}
          onClick={async () => {
            setIsOpenExpiry(false);

            await StorageService.set("inactivity", false);
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="12"
            height="12"
            viewBox="0 0 12 12"
            fill="none"
          >
            <path
              d="M0.33546 0.33546C0.550319 0.120665 0.841693 0 1.1455 0C1.44932 0 1.74069 0.120665 1.95555 0.33546L6.00692 4.38683L10.0583 0.33546C10.2744 0.126752 10.5638 0.0112672 10.8642 0.0138777C11.1646 0.0164882 11.452 0.136985 11.6644 0.349417C11.8768 0.561848 11.9973 0.849216 12 1.14963C12.0026 1.45004 11.8871 1.73946 11.6784 1.95555L7.62701 6.00692L11.6784 10.0583C11.8871 10.2744 12.0026 10.5638 12 10.8642C11.9973 11.1646 11.8768 11.452 11.6644 11.6644C11.452 11.8768 11.1646 11.9973 10.8642 12C10.5638 12.0026 10.2744 11.8871 10.0583 11.6784L6.00692 7.62701L1.95555 11.6784C1.73946 11.8871 1.45004 12.0026 1.14963 12C0.849216 11.9973 0.561848 11.8768 0.349417 11.6644C0.136985 11.452 0.0164882 11.1646 0.0138777 10.8642C0.0112672 10.5638 0.126752 10.2744 0.33546 10.0583L4.38683 6.00692L0.33546 1.95555C0.120665 1.74069 0 1.44932 0 1.1455C0 0.841693 0.120665 0.550319 0.33546 0.33546Z"
              fill={
                theme === "dark" ? "#858585" : "var(--content-main-black-light)"
              }
            />
          </svg>
        </button>
      </div>

      <p
        className={`${
          type === "Expiry" ? "w-[95%]" : "w-[100%] text-center"
        } text-base text-[var(--content-main-black-light)] dark:text-white`}
      >
        {isInactive
          ? "Tu sesión se cerró por inactividad. Inicia sesión nuevamente para continuar."
          : "Hemos detectado inactividad en tu sesión. En breve se cerrará automáticamente."}
      </p>
      {isLoading && (
        <div className="absolute inset-0 flex flex-col items-center justify-center gap-3 bg-white z-50">
          <svg
            className="loader"
            viewBox="0 0 384 384"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle
              className="active"
              pathLength="360"
              fill="transparent"
              stroke-width="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
            <circle
              className="track"
              pathLength="360"
              fill="transparent"
              stroke-width="32"
              cx="192"
              cy="192"
              r="176"
            ></circle>
          </svg>
          <p className="text-base text-[var(--content-main-black-light)] dark:text-white">
            Cerrando sesión...
          </p>
        </div>
      )}
    </motion.div>
  );
};

export default ModalInactAndExpi;
