import CustomButton from "@/apps/Shared/CustomButton/CustomButton";
import CustomModal from "@/apps/Shared/CustomModal/CustomModal";
import {
  IconClose,
  IconCrypto,
  IconDolar,
  IconVVankPay,
} from "@/assets/IconV2/IconsSvg";
import { useTheme } from "@/Context/UseContext/ThemeContext";
import { motion } from "framer-motion";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";

const SendModal = ({
  isOpenSend,
  setIsOpenSend,
}: {
  isOpenSend: any;
  setIsOpenSend: any;
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHoverPay, setIsHoverPay] = useState(false);
  const [isHoverCrypto, setIsHoverCrypto] = useState(false);
  const [isHoverDolar, setIsHoverDolar] = useState(false);
  const [t, i18n] = useTranslation("global");
  const [isMobile, setIsMobile] = useState(false);

  const navigate = useNavigate();
  const { theme, toggleDarkMode } = useTheme(); // Usa el contexto

  useEffect(() => {
    // Función para verificar si el dispositivo es móvil
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 768); // Consideramos móvil si el ancho es menor a 768px
    };

    // Verifica el tamaño de la ventana al cargar el componente
    checkMobile();

    // Añade un listener para manejar el cambio de tamaño de la ventana
    window.addEventListener("resize", checkMobile);

    return () => {
      window.removeEventListener("resize", checkMobile); // Limpia el listener
    };
  }, []);

  const handleMouseHoverClose = (hovered: boolean) => {
    setIsHovered(hovered);
  };

  const handleMouseHoverPay = (hovered: boolean) => {
    setIsHoverPay(hovered);
  };

  const handleMouseHoverCrypto = (hovered: boolean) => {
    setIsHoverCrypto(hovered);
  };

  const handleMouseHoverDolar = (hovered: boolean) => {
    setIsHoverDolar(hovered);
  };

  const handleNavigate = (path: string) => {
    setIsOpenSend(false);
    setIsHovered(false);
    setIsHoverPay(false);
    setIsHoverCrypto(false);
    setIsHoverDolar(false);
    setTimeout(() => {
      navigate(path);
    }, 70);
  };

  return (
    <CustomModal isOpen={isOpenSend}>
      <motion.div
        className="2xl:w-[550px] xl:w-[550px] lg:w-[550px] md:w-[550px] sm:w-[550px] 2xl:relative xl:relative lg:relative md:relative sm:relative mobile:absolute mobile:bottom-0 mobile:w-full h-[282px] px-4 py-6 bg-[var(--bacground-component-module-light)] dark:bg-[#1A1A1B] xl:rounded-2xl 2xl:rounded-2xl tablet:rounded-2xl mobile:rounded-t-2xl flex-col justify-center items-center gap-6 inline-flex"
        initial={isMobile ? { y: "100%" } : { y: 0 }} // Solo en móvil comienza fuera de la pantalla
        animate={{ y: 0 }} // Siempre termina en su posición original
        exit={isMobile ? { y: "100%" } : { y: 0 }} // Sale hacia abajo solo en móvil
        transition={{ duration: 0.3, ease: "easeInOut" }} // Duración y suavizado de la transición
      >
        <div className="2xl:w-[518px]  xl:w-[518px] lg:w-[518px] md:w-[518px] sm:w-[518px] mobile:w-full h-12 flex-col justify-start items-start inline-flex">
          <div className="w-full h-7 justify-between items-center inline-flex ">
            <span className="w-full text-[var(--content-main-black-light)] dark:text-[#FFFFFF] 2xl:text-[22px] xl:text-[22px] lg:text-[22px] md:text-[22px] sm:text-[22px] mobile-375:text-[18px] mobile:text-[17px] font-semibold">
              {t("Vank.Share.Sidebar.SendModal.title")}
            </span>
            <CustomButton
              className={`w-7 h-7 rounded-[500px] 2xl:border-none xl:border-none lg:border-none md:border-none sm:border-none mobile:border border-[#F1F1F1] justify-center items-center flex hover:bg-[var(--content-main-black-light)] dark:hover:bg-[#FFFFFF] ${
                theme === "dark" ? "dark-theme-back" : "light-theme-back"
              } transition-all duration-300`}
              onclick={() => {
                setIsOpenSend(false);
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="18"
                height="19"
                viewBox="0 0 18 19"
                fill="none"
                className="w-[18px] h-[18px] icon-svg"
              >
                <path
                  d="M5 5.5L13 13.5"
                  // stroke="var(--content-main-gray-light)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
                <path
                  d="M13 5.5L5 13.5"
                  // stroke="var(--content-main-gray-light)"
                  stroke-width="1.5"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                />
              </svg>
            </CustomButton>
          </div>
          <span className="text-start text-[var(--content-main-black-light)] dark:text-[#FFFFFF] text-sm font-normal leading-tight">
            {t("Vank.Share.Sidebar.SendModal.message")}
          </span>
        </div>

        <div className="2xl:w-auto xl:w-auto lg:w-auto tablet:w-auto mobile:w-full justify-start items-center gap-4 inline-flex">
          <div
            className="group 2xl:w-[162px] xl:w-[162px] lg:w-[162px] tablet:w-[162px] mobile:w-full  w-full text-center h-[162px] py-10  bg-[var(--branding-brand-yellow-light)] text-[var(--content-inner-inner-black-light)] hover:bg-[var(--content-inner-inner-black-light)] hover:text-[var(--branding-brand-yellow-light)] rounded-2xl flex-col justify-center items-center gap-3 inline-flex  cursor-pointer"
            onMouseEnter={() => handleMouseHoverPay(true)}
            onMouseLeave={() => handleMouseHoverPay(false)}
            onClick={() => handleNavigate("/send/vankpay")}
          >
            <div className="flex flex-col justify-center items-center gap-3 group-hover:gap-2">
              <div className=" w-[84px] h-[84px] group-hover:h-[69px] group-hover:scale-[0.95] flex justify-center items-center  transition-all ease-in-out duration-300">
                <IconVVankPay
                  className="w-full h-full "
                  fill={isHoverPay && "var(--branding-brand-yellow-light)"}
                />
              </div>
              <div className="text-center text-lg font-semibold ">
                {t("Vank.Share.Sidebar.SendModal.Actions.VankPay.title")}
              </div>
              <span className="group-hover:block px-[5px] hidden text-xs">
                {t("Vank.Share.Sidebar.SendModal.text1")}
              </span>
            </div>
          </div>

          {/* <div className="group w-[162px] h-[162px] text-center bg-[var(--branding-brand-yellow-light)] text-[var(--content-inner-inner-black-light)] hover:bg-[var(--content-inner-inner-black-light)] hover:text-[var(--branding-brand-yellow-light)] rounded-2xl flex-col justify-center items-center gap-3 inline-flex transition-all duration-500 cursor-pointer"
            onMouseEnter={() => handleMouseHoverCrypto(true)}
            onMouseLeave={() => handleMouseHoverCrypto(false)}
            onClick={() => handleNavigate('/send/crypto')}
          >
            <div className='flex flex-col gap-3 group-hover:h-24 group-hover:gap-2'>

              <div className="w-[84px] h-[84px] flex justify-center items-center ">
                <IconCrypto className="w-full h-full group-hover:h-14 " stroke={isHoverCrypto && 'var(--branding-brand-yellow-light)'} />
              </div>
              <div className="text-center text-lg font-semibold ">{t("Vank.Share.Sidebar.SendModal.Actions.Crypto.title")}</div>

            </div>

            <span className='group-hover:block px-[5px] hidden text-xs'>{t("Vank.Share.Sidebar.SendModal.text2")}</span>

          </div> */}

          <div
            className="group 2xl:w-[162px] xl:w-[162px] lg:w-[162px] tablet:w-[162px] mobile:w-full  h-[162px] text-center bg-[var(--branding-brand-yellow-light)] hover:pt-[10px] hover:pb-[8px] text-[var(--content-inner-inner-black-light)] hover:bg-[var(--content-inner-inner-black-light)] hover:text-[var(--branding-brand-yellow-light)] rounded-2xl flex-col justify-center items-center gap-3 inline-flex transition-all duration-100 cursor-pointer"
            onMouseEnter={() => handleMouseHoverDolar(true)}
            onMouseLeave={() => handleMouseHoverDolar(false)}
            onClick={() => handleNavigate("/send/fiat")}
          >
            <div className="flex  flex-col justify-center items-center gap-3 group-hover:gap-2 ">
              <div className=" w-[84px] h-[84px] group-hover:h-[69px] group-hover:scale-[0.95] flex justify-center items-center  transition-all ease-in-out duration-300">
                <IconDolar
                  className="w-full h-full "
                  stroke={isHoverDolar && "var(--branding-brand-yellow-light)"}
                />
              </div>
              <div className="text-center text-lg font-semibold ">
                {t("Vank.Share.Sidebar.SendModal.Actions.Fiat.title")}
              </div>
              <span className="h-[0px] group-hover:h-[30px] px-[5px] text-xs overflow-hidden">
                {t("Vank.Share.Sidebar.SendModal.text3")}
              </span>
            </div>
          </div>
        </div>
      </motion.div>
    </CustomModal>
  );
};

export default SendModal;
