import { AxiosClientRepository } from "@/apps/Shared/infrastructura/Http/AxiosHttp";
import { environment } from "@/environments/environment.dev";

export class ExternalServices extends AxiosClientRepository {
  //   convertAsset
  // query: ?amount=50000&from=COP&to=USD
  public async getRealTimeValueCoinmarket(data: any) {
    try {
      const url = `${environment.url_servers.url_users}/secure/v1/convertAsset?amount=${data.amount}&from=USD&to=COP`;
      let response = await this.get({ url });
      return response;
    } catch (error) {
      throw error;
    }
  }

  public shareWhatsApp(mensaje: string) {
    return `https://api.whatsapp.com/send?text=${encodeURIComponent(mensaje)}`;
  }

  public shareEmail(data: any) {
    return `https://mail.google.com/mail/?view=cm&fs=1&to=${encodeURIComponent(
      data.destinatario
    )}?&su=${encodeURIComponent(data.asunto)}&body=${encodeURIComponent(
      data.cuerpo
    )}`;
  }
}
