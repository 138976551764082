import { RouterProvider } from "react-router-dom";
import { Context } from "@redtea/react-inversify";
import router from "./Router";
import "react-date-range/dist/styles.css"; // main css file
import "react-date-range/dist/theme/default.css"; // theme css file
import { CacheDropDowns } from "./apps/Shared/infrastructura/Persistence/CacheDropDowns";
import { useEffect, useState } from "react";
import ErrorInfoModal from "./apps/Auth/ErrorInfoModal/ErrorInfoModal";
import CustomModal, { backStyle } from "./apps/Shared/CustomModal/CustomModal";
import { AnimatePresence, motion } from "framer-motion";
import { isMobile, isTablet, isDesktop } from "react-device-detect";
import pulse1 from "@assets/img/pulse1.gif";
import { ToastContainer } from "react-toastify";
import ModalInactAndExpi from "./apps/Root/RootVank/ModalInactAndExpi/ModalInactAndExpi";

import { StorageService } from "@/apps/Vank/Page/services/StorageServiceVanKPay";

function App({ container }) {
  const cacheDropdowns = new CacheDropDowns();

  const [isInactive, setIsInactive] = useState(async () => {
    const inactiveStatus = await StorageService.get("inactivity");
    return inactiveStatus === null ? false : inactiveStatus;
  });
  const [type, setType] = useState("");
  const [isOpenExpiry, setIsOpenExpiry] = useState(false);
  const [isClose, setIsClose] = useState(false);
  let timeoutId; // Declara timeoutId fuera del estado

  // Configuración del temporizador de inactividad (5 minutos)
  const INACTIVITY_TIME = 5 * 60 * 1000; // 5 minutos)
  const _INACTIVITY_TIME = import.meta.env.VITE_HALF_MINUTE;
  const INACT_TIME_ONE_DAY = import.meta.env.VITE_ONE_DAY;

  const resetTimer = async () => {
    // Limpia el temporizador anterior si existe
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    // Configura un nuevo temporizador
    timeoutId = setTimeout(() => {
      setType("Inactive");
      setIsOpenExpiry(true);
      // localStorage.setItem("Inactive", "false");
      setIsClose(true);
    }, INACTIVITY_TIME);
  };

  const handleUserActivity = async () => {
    const inactivity = await StorageService.get("inactivity");
    if (inactivity) {
      setType("Inactive");
      setIsOpenExpiry(true);
    } else {
      setType("Inactive");
      setIsOpenExpiry(false);
    }

    const token = await StorageService.get("token");
    if (token && token !== "undefined") {
      resetTimer();
    } else {
      return;
      console.warn("No token found, inactivity timer will not be reset.");
    }
  };

  useEffect(() => {
    window.addEventListener("mousemove", handleUserActivity);
    window.addEventListener("keydown", handleUserActivity);
    window.addEventListener("click", handleUserActivity);
    window.addEventListener("scroll", handleUserActivity);
    window.addEventListener("touchmove", handleUserActivity);
    window.addEventListener("touchstart", handleUserActivity);

    // window.addEventListener("beforeunload", handleBeforeUnload);

    handleUserActivity();
    // const INACTIVITY_TIME = parseInt(import.meta.env.VITE_INACTIVITY_TIME, 10);
    // alert(INACTIVITY_TIME);

    return () => {
      clearTimeout(timeoutId);
      window.removeEventListener("mousemove", handleUserActivity);
      window.removeEventListener("keydown", handleUserActivity);
      window.removeEventListener("click", handleUserActivity);
      window.removeEventListener("scroll", handleUserActivity);
      window.removeEventListener("touchmove", handleUserActivity);
      window.removeEventListener("touchstart", handleUserActivity);
    };
  }, []);

  useEffect(() => {
    cacheDropdowns
      .cache()
      .then(() => {
        // setIsLoading(false);
      })
      .catch((e) => {});
  }, []);

  return (
    <Context.Provider value={container}>
      <RouterProvider router={router} />
      <ToastContainer position="top-right" />
      <CustomModal isOpen={isOpenExpiry}>
        <ModalInactAndExpi
          // type={type}
          type="Inactive"
          isClose={isClose}
          setIsOpenExpiry={setIsOpenExpiry}
        />
      </CustomModal>
    </Context.Provider>
  );
}

export default App;
