import { atom } from "recoil";

export const updateBalnceAtom=atom({
   key:"updateBalnceAtom",
   default:false
})


// export const postAvailableStatus=atom({
//     key:"available",
//     default:{
//         status:false
//     }
// })