import {atom} from "recoil";

export const postDataUserFound=atom({
    key:"valueDataUserFound",
    default:{
        FROM_EMAIL:"",
        FROM_ACCOUNT_TYPE:"SPOT",
        TO_EMAIL:"",
        TO_ACCOUNT_TYPE:"SPOT",
        ASSET:"",
        AMOUNT:"",
        TO_VIRTUALEMAIL:"",
        CODEASSET:"",
        TYPEACTION:""
    }
})