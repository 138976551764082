import { lazy } from "react";
import { createBrowserRouter, redirect, redirectDocument } from "react-router-dom";
import RootLanding from "./apps/Root/RootLanding/RootLanding";
import { RootVank } from "./apps/Root/RootVank/RootVank";
import Recipient from "./apps/Vank/Page/Recipient/Recipient";
import RecipientRoutes from "./apps/Vank/Page/Recipient/router/RecipientRoutes";
import Fiat from "./apps/Vank/Page/Transactions/Fiat/Fiat";

const router = createBrowserRouter([
  {
    path: "/",
    loader: () => redirect("/Auth/SingIn"),
  },
  //TODO: LANDING
  {
    element: <RootLanding />,
    children: [
      {
        path: "/",
        Component: lazy(() => import("./apps/Landing/Pages/Home/Home")),
      },
      {
        path: "/People",
        Component: lazy(() => import("./apps/Landing/Pages/People/People")),
      },
      {
        path: "/Companies",
        Component: lazy(() => import("./apps/Landing/Pages/Company/Company")),
      },
      {
        path: "/VankCards",
        Component: lazy(() => import("./apps/Landing/Pages/VankCard/VankCard")),
      },
    ],
  },
  //TODO: AUTH
  {
    path: "",
    children: [
      {
        path: "Auth/:mode",
        Component: lazy(() => import("./apps/Auth/Auth")),
      },
      {
        path: "Auth/AddPhone",
        Component: lazy(() => import("./apps/Auth/SingUp/PhoneForm/PhoneForm")),
      },
      {
        path: "Auth/OTPValidation",
        Component: lazy(
          () =>
            import("./apps/Auth/SingUp/OTPValidationPhone/OTPValidationPhone")
        ),
      },
      {
        path: "Auth/SetPassword",
        Component: lazy(
          () => import("./apps/Auth/SingUp/SetPassword/SetPassword")
        ),
      },
      {
        path: "Auth/Person",
        Component: lazy(
          () =>
            import("./apps/Auth/SingUp/MultiStepFormPerson/MultiStepFormPerson")
        ),
      },
    ],
  },
  //TODO: HOME
  {
    element: <RootVank />,
    children: [
      {
        path: "/Home",
        Component: lazy(() => import("@/apps/Vank/Page/Home/Home")),
      },
      {
        path: "/send/vankpay",
        Component: lazy(
          () => import("@/apps/Vank/Page/Transactions/VankPay/VankPay")
        ),
      },
      {
        path: "/send/crypto",
        Component: lazy(
          () =>
            import("@/apps/Vank/Page/Transactions/Crypto/SendCrypto/SendCrypto")
        ),
      },
      {
        path: "/convert",
        Component: lazy(() => import("@/apps/Vank/Page/Convert/Convert")),
      },
      {
        // path: "/send/fiat",
        // Component: lazy(
        //   () => import("@/apps/Vank/Page/Transactions/Fiat/Fiat")
        // ),
        path: "/send/fiat/*",
        element: <Fiat />,
        children: [
          {
            path: "*",
            Component: lazy(
              () =>
                import("@/apps/Vank/Page/Transactions/Fiat/routers/FiatRouter")
            ),
          },
        ],
      },
      {
        path: "/receive/fiat",
        Component: lazy(
          () =>
            import(
              "@/apps/Vank/Page/Transactions/Fiat/RechargeFiat/RechageFiat"
            )
        ),
      },
      {
        path: "/recipient/*", // La ruta base para Recipient
        element: <Recipient />,
        children: [
          // Aquí importamos las rutas que están en RecipientRoutes
          {
            path: "*",
            Component: lazy(
              () => import("@/apps/Vank/Page/Recipient/router/RecipientRoutes")
            ),
          },
        ],
      },
      {
        path: "/settings",
        Component: lazy(
          () =>
            import(
              "./apps/Vank/Page/settings/settings"
            )
        ),
      },
    ],
  },
  //TODO: LINK EXTERNO DE RECARGAR DINERO
  {
    path: "/ExternalRecharge/:encodedData",
    Component: lazy(
      () =>
        import(
          "./apps/Vank/Page/Transactions/Fiat/RechargeFiat/ExternalRecharge/ExternalRecharge"
        )
    ),
  },
  //TODO: PAGINAS NO ENCONTRADAS
  {
    path: "*",
    Component: lazy(() => import("./apps/Shared/NotFound/NotFound")),
  },
]);

export default router;
